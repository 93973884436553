import { Link, View, Text } from '@react-pdf/renderer'
import _ from 'lodash'

import styles from 'app/components/action/pdfViewer/field/fieldBuilder/styles'
import FieldChildren from 'app/components/action/pdfViewer/field/fieldChildren'
import { FIELD_TYPES } from 'config/enums'
import IOption from 'interfaces/common/IOption'
import IField from 'interfaces/field/IField'
import IFile from 'interfaces/file/IFile'
import PDFHelper from 'utils/form/PDFHelper'
import FormFieldValidation from 'utils/formField/FormFieldValidation'

interface IViewHelperProps {
  /** IField interface */
  field: IField
  /** IOption interface */
  option: IOption
  /** Option label */
  label: string
}

export const ViewHelper: React.FC<IViewHelperProps> = ({
  option,
  field,
  label,
}: IViewHelperProps): React.ReactElement => (
  <View key={option.id}>
    <Text style={_.isEqual(field.type, FIELD_TYPES.RADIO) ? styles.text : styles.listItem}>{label}</Text>
    <FieldChildren field={field} optionValue={option.id} />
  </View>
)

interface IFieldBuilderProps {
  /** IField interface */
  field: IField
}

/**
 * Field build component
 * @param {object} props
 * @param {IField} props.field
 */
const FieldBuilder: React.FC<IFieldBuilderProps> = ({ field }: IFieldBuilderProps): React.ReactElement => {
  const { value, isHidden } = field

  if (isHidden) {
    return <></>
  }

  let elementValue = <></>
  switch (field.type) {
    case FIELD_TYPES.FILE_UPLOAD: {
      elementValue = _.isArray(value) ? (
        <View style={styles.list}>
          {value.map((file: IFile) => (
            <Link key={file.id} style={styles.listItem} src={file.file.url}>
              {file.file.name}
            </Link>
          ))}
        </View>
      ) : (
        <></>
      )
      break
    }
    case FIELD_TYPES.TEXT_INPUT:
    case FIELD_TYPES.TEXT_AREA:
    case FIELD_TYPES.CURRENCY:
    case FIELD_TYPES.DATE_PICKER: {
      elementValue = <Text style={styles.text}>{_.toString(value)}</Text>
      break
    }
    case FIELD_TYPES.RADIO: {
      const option: undefined | IOption = PDFHelper.getRadioValue(field)
      elementValue = !_.isUndefined(option) ? <ViewHelper label={option.label} option={option} field={field} /> : <></>
      break
    }
    case FIELD_TYPES.CHECKBOX: {
      elementValue = _.isArray(value) ? (
        <View style={styles.list}>
          {_.orderBy(value, ['id'], 'asc').map((option: IOption) => {
            return <ViewHelper key={option.id} label={`- ${option.label}`} option={option} field={field} />
          })}
        </View>
      ) : (
        <></>
      )
      break
    }
    case FIELD_TYPES.SELECT:
    case FIELD_TYPES.MARKET:
    case FIELD_TYPES.USER:
    case FIELD_TYPES.AUTOCOMPLETE: {
      elementValue = _.isArray(value) ? (
        <View style={styles.list}>
          {value.map((val: string) => (
            <View key={val}>
              <Text key={val} style={styles.listItem}>
                - {val}
              </Text>
            </View>
          ))}
        </View>
      ) : (
        <></>
      )
      break
    }
  }

  let isEmptyField = FormFieldValidation.isEmptyField(field)

  return (
    <View style={styles.fieldContainer}>
      <Text style={styles.fieldHeader}>{field.fieldConfig.text}</Text>
      {isEmptyField ? <Text style={styles.text}>-</Text> : elementValue}
    </View>
  )
}

export default FieldBuilder
