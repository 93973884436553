// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YfxqE::part(body-wrapper){padding:0}.SdpQF{height:98%}.b70AN{display:flex;flex-direction:row-reverse}`, "",{"version":3,"sources":["webpack://./src/app/components/action/pdfViewer/PdfViewer.module.scss"],"names":[],"mappings":"AACE,2BACE,SAAA,CAIJ,OACE,UAAA,CAGF,OACE,YAAA,CACA,0BAAA","sourcesContent":[".modal {\n  &::part(body-wrapper) {\n    padding: 0;\n  }\n}\n\n.modalBody {\n  height: 98%;\n}\n\n.modalActions {\n  display: flex;\n  flex-direction: row-reverse;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `YfxqE`,
	"modalBody": `SdpQF`,
	"modalActions": `b70AN`
};
export default ___CSS_LOADER_EXPORT___;
