import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'

import styles from 'app/components/form/formField/formFieldComment/FormFieldCommentIcon/FormFieldCommentIcon.module.scss'
import { WppAvatarGroup, WppIconChatMessage } from 'buildingBlocks'
import { QUESTIONNAIRE_STATUS } from 'config/enums'
import IFieldComment from 'interfaces/field/fieldComment/IFieldComment'
import { RootState } from 'store'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import FieldCommentHelper from 'utils/formField/FieldCommentHelper'

interface IFormFieldCommentIconProps {
  /** Field comment */
  fieldComment: IFieldComment
}

/**
 * Create icon for the field comment
 * @param {object} props
 * @param {IFieldComment} props.fieldComment
 */
const FormFieldCommentIcon: React.FC<IFormFieldCommentIconProps> = ({
  fieldComment,
}: IFormFieldCommentIconProps): React.ReactElement => {
  const { questionnaire } = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)
  const { enableCommentMode } = useSelector<RootState, IQuestionnaireState>(
    (state: RootState) => state.questionnaireState,
  )

  return (
    <div slot="trigger-element">
      {_.isEmpty(fieldComment.uniqueUsers) &&
        !_.isEqual(questionnaire.approval?.status, QUESTIONNAIRE_STATUS.SUCCEEDED) &&
        enableCommentMode && <WppIconChatMessage className={styles.chatMessageIcon} />}
      {!_.isEmpty(fieldComment.uniqueUsers) && (
        <div className={styles.avatarContainer}>
          <div className={styles.avatar}>
            <div className={styles.arrow} />
            <WppAvatarGroup
              className={styles.avatarGroup}
              maxAvatarsToDisplay={2}
              size="xs"
              withTooltip
              avatars={FieldCommentHelper.getCommentUsers(fieldComment.uniqueUsers)}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default FormFieldCommentIcon
