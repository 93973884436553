import _ from 'lodash'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import styles from 'buildingBlocks/table/tableBody/TableBody.module.scss'
import TableLoader from 'buildingBlocks/table/tableLoader'
import ITable from 'interfaces/table/ITable'
import ITableHeader from 'interfaces/table/ITableHeader'
import ITableRow from 'interfaces/table/ITableRow'

interface ITableBodyProps {
  table: ITable
}

/**
 * Table body component
 * @param {object} props
 * @param {ITable} props.table
 */
const TableBody: React.FC<ITableBodyProps> = ({ table }: ITableBodyProps): React.ReactElement => {
  const { colDefs, loading, rowData, paginator } = table
  if (loading) {
    return <TableLoader colDefs={colDefs} itemsPerPage={paginator.itemsPerPage} />
  }

  return (
    <tbody>
      {rowData.map((data: ITableRow) => {
        return (
          <tr key={uuidv4()} className={styles.tableRow}>
            {colDefs.map((col: ITableHeader) => {
              return (
                <td key={col.id} className={styles.tableCell}>
                  {data[col.id].component ?? (
                    <span
                      className={styles.text}
                      dangerouslySetInnerHTML={{
                        __html: _.toString(data[col.id].text),
                      }}
                    />
                  )}
                </td>
              )
            })}
          </tr>
        )
      })}
    </tbody>
  )
}

export default TableBody
