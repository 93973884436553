import _ from 'lodash'
import React from 'react'

import FieldBuilder from 'app/components/action/pdfViewer/field/fieldBuilder'
import IField from 'interfaces/field/IField'
import IRepeatableField from 'interfaces/field/repeatable/IRepeatableField'
import PDFHelper from 'utils/form/PDFHelper'

interface IFieldProps {
  /** IField interface */
  field: IField
}

/**
 * PDF field component
 * @param {object} props
 * @param {IField} props.field
 */
const Field: React.FC<IFieldProps> = ({ field }: IFieldProps): React.ReactElement => {
  if (_.gt(field.maxRepeatableAmount, 0)) {
    return (
      <>
        {field.repeatableFields.map((rField: IRepeatableField, index: number) => {
          const updatedField: IField = { ...field, value: rField.value }
          const fieldValue = PDFHelper.getFieldValue(updatedField)
          const fieldLabel = !field.fieldConfig.text ? '' : `${field.fieldConfig.text} ${index + 1}`

          return (
            <FieldBuilder
              key={rField.id}
              field={{
                ...field,
                value: fieldValue,
                fieldConfig: {
                  ...field.fieldConfig,
                  text: fieldLabel,
                },
              }}
            />
          )
        })}
      </>
    )
  }
  return <FieldBuilder field={{ ...field, value: PDFHelper.getFieldValue(field) }} />
}

export default Field
