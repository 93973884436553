import _ from 'lodash'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import styles from 'app/components/form/formField/formFieldComment/FormFieldComment.module.scss'
import FormFieldCommentDetail from 'app/components/form/formField/formFieldComment/formFieldCommentDetail'
import FormFieldCommentEditor from 'app/components/form/formField/formFieldComment/formFieldCommentEditor'
import FormFieldCommentIcon from 'app/components/form/formField/formFieldComment/FormFieldCommentIcon'
import { WppPopover, WppProgressIndicator } from 'buildingBlocks'
import { COMMENTS_PER_PAGE, TOAST_DURATION } from 'config/constants'
import { QUESTIONNAIRE_STATUS, TOAST_MESSAGE_TYPES } from 'config/enums'
import useToast from 'hooks/useToast'
import IComment from 'interfaces/field/fieldComment/IComment'
import IFieldComment from 'interfaces/field/fieldComment/IFieldComment'
import IField from 'interfaces/field/IField'
import { RootState } from 'store'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import FieldCommentHelper from 'utils/formField/FieldCommentHelper'

interface IFormFieldCommentProps {
  /** Field object */
  field: IField
  /** Handle comment data */
  handleComment: (field: IField, value: string) => void
  /** Form Field comments */
  setFormFieldComments: (field: IField, fieldComment: IFieldComment) => void
}

/**
 * Create label for the field
 * @param {object} props
 * @param {IField} props.field
 * @param {(field: IField, value: string) => void} props.handleComment
 * @param {(field: IField, fieldComment: IFieldComment) => void} props.setFormFieldComments
 */
const FormFieldComment: React.FC<IFormFieldCommentProps> = ({
  field,
  handleComment,
  setFormFieldComments,
}: IFormFieldCommentProps): React.ReactElement => {
  const { app } = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)
  const { questionnaire } = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)

  const { showToast } = useToast()
  const [loading, setLoading] = useState<boolean>(false)
  const popoverRef: React.RefObject<HTMLWppPopoverElement> = useRef<HTMLWppPopoverElement>(null)

  const loadMoreComment = async () => {
    const { page, startDate } = field.fieldComment
    const updatedStartDate = _.isNull(startDate) ? new Date().toISOString() : startDate

    const newComments: IComment[] = await FieldCommentHelper.loadMoreComment(field, page, updatedStartDate)

    const hasMore = !_.isEmpty(newComments) && _.isEqual(newComments.length, COMMENTS_PER_PAGE)
    setFormFieldComments(field, {
      ...field.fieldComment,
      comments: _.uniqBy(_.concat(field.fieldComment.comments, newComments), 'id'),
      page: page + 1,
      hasMore,
      startDate: updatedStartDate,
    })
  }

  return (
    <div className={styles.rootContainer}>
      <WppPopover
        ref={popoverRef}
        config={{
          onShow: () => {
            if (field.fieldComment.hasMore && !_.isEmpty(field.fieldComment.uniqueUsers)) {
              setLoading(true)
              loadMoreComment()
                .then(() => {
                  setLoading(false)
                })
                .catch(() => {
                  setLoading(false)
                  showToast({
                    header: '',
                    message: 'Unable to fetch comments',
                    type: TOAST_MESSAGE_TYPES.ERROR,
                    duration: TOAST_DURATION,
                  })
                })
            }
          },
        }}
      >
        <FormFieldCommentIcon fieldComment={field.fieldComment} />
        <div className={styles.commentContainer}>
          {loading && <WppProgressIndicator />}
          <FormFieldCommentDetail field={field} loadMoreComment={loadMoreComment} />
          {!_.isEqual(questionnaire.approval?.status, QUESTIONNAIRE_STATUS.SUCCEEDED) && app && app.isProjectMember && (
            <FormFieldCommentEditor field={field} handleComment={handleComment} popoverRef={popoverRef} />
          )}
        </div>
      </WppPopover>
    </div>
  )
}

export default FormFieldComment
