import { TFunction } from 'i18next'

import IChangeLogData from 'interfaces/changeLog/IChangeLogData'
import ITableHeader from 'interfaces/table/ITableHeader'
import ITableRow from 'interfaces/table/ITableRow'

export const getColumnData = (t: TFunction): ITableHeader[] => {
  return [
    {
      id: 'date',
      name: t('changeLog.logList.columnData.date'),
      width: 200,
      enableOrderBy: true,
    },
    {
      id: 'category',
      name: t('changeLog.logList.columnData.category'),
      width: 150,
    },
    {
      id: 'fieldName',
      name: t('changeLog.logList.columnData.fieldName'),
      width: 150,
    },
    {
      id: 'oldValue',
      name: t('changeLog.logList.columnData.oldValue'),
      minWidth: 150,
    },
    {
      id: 'newValue',
      name: t('changeLog.logList.columnData.newValue'),
      minWidth: 150,
    },
    {
      id: 'userEmail',
      name: t('changeLog.logList.columnData.userEmail'),
      width: 140,
    },
  ]
}

export const getRowData = (changeLogData: IChangeLogData[]): ITableRow[] => {
  const tableRows: ITableRow[] = changeLogData.map(
    ({ category, date, fieldName, newValue, oldValue, userEmail }: IChangeLogData) => {
      return {
        date: {
          text: date,
        },
        category: {
          text: category,
        },
        fieldName: {
          text: fieldName,
        },
        newValue: {
          text: newValue,
        },
        oldValue: {
          text: oldValue,
        },
        userEmail: {
          text: userEmail,
        },
      }
    },
  )
  return tableRows
}
