// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bWUyx{cursor:pointer;margin-left:-24px;margin-top:-24px}.KxtzP{padding:12px 16px;width:350px;display:flex;flex-direction:column;gap:24px}.KxtzP .G2GOU{display:flex;flex-direction:column;gap:12px}.KxtzP .G2GOU .zGBsi{display:flex;align-items:center;gap:8px;flex-wrap:wrap}.KxtzP .G2GOU .Tv9Ah{display:flex;justify-content:flex-end;gap:4px}`, "",{"version":3,"sources":["webpack://./src/app/components/form/formField/formFieldComment/FormFieldComment.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CACA,iBAAA,CACA,gBAAA,CAGF,OACE,iBAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,cACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,qBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,cAAA,CAGF,qBACE,YAAA,CACA,wBAAA,CACA,OAAA","sourcesContent":[".rootContainer {\n  cursor: pointer;\n  margin-left: -24px;\n  margin-top: -24px;\n}\n\n.commentContainer {\n  padding: 12px 16px;\n  width: 350px;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n\n  .commentEditor {\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n\n    .userInfo {\n      display: flex;\n      align-items: center;\n      gap: 8px;\n      flex-wrap: wrap;\n    }\n\n    .actions {\n      display: flex;\n      justify-content: flex-end;\n      gap: 4px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rootContainer": `bWUyx`,
	"commentContainer": `KxtzP`,
	"commentEditor": `G2GOU`,
	"userInfo": `zGBsi`,
	"actions": `Tv9Ah`
};
export default ___CSS_LOADER_EXPORT___;
