import { useOs } from '@wppopen/react'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import FormFieldUser from 'app/components/form/formField/FormFieldUser'
import {
  WppActionButton,
  WppButton,
  WppFilterButton,
  WppListItem,
  WppSelect,
  WppSideModal,
  WppTypography,
} from 'buildingBlocks'
import ICategory from 'interfaces/category/ICategory'
import ISelectedFilter from 'interfaces/changeLog/ISelectedFilter'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import styles from 'pages/changeLog/filter/Filter.module.scss'
import { AppDispatch, RootState } from 'store'
import IChangeLogState from 'store/interfaces/IChangeLogState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import {
  setFilterData,
  setFilterFormFields,
  setOpenFilterModal,
  setSelectedFilterData,
} from 'store/reducers/changeLogSlice'
import { SelectChangeEventDetail, WppSelectCustomEvent } from 'utils/types'

const SideModalFilter: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const { osApi } = useOs()
  const { t } = useTranslation()
  const { openFilterModal, filterData, filterSelectedData } = useSelector<RootState, IChangeLogState>(
    (state: RootState) => state.changeLogState,
  )
  const questionnaireState = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)

  const [formData, setFormData] = useState<ISelectedFilter>(filterSelectedData.sideModal)

  const setFilterModal = (open: boolean) => {
    dispatch(setOpenFilterModal(open))
  }

  useEffect(() => {
    if (questionnaireState.app) {
      dispatch(setFilterData(questionnaireState.app))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireState.app])

  useEffect(() => {
    if (openFilterModal) setFormData(filterSelectedData.sideModal)
  }, [filterSelectedData.sideModal, openFilterModal])

  const userFieldConfig: IField = {
    ...formData.selectedUserField,
    fieldConfig: { ...formData.selectedUserField.fieldConfig, accessToken: osApi.getAccessToken() },
  }

  return (
    <>
      <WppFilterButton onClick={() => setFilterModal(true)}>
        <Trans>app.button.filter</Trans>
      </WppFilterButton>
      {createPortal(
        <WppSideModal
          data-testid="side-modal"
          size="s"
          open={openFilterModal}
          onWppSideModalClose={() => setFilterModal(false)}
          onWppSideModalOpen={() => setFilterModal(true)}
          disableOutsideClick={true}
        >
          <h3 slot="header">
            <Trans>changeLog.filter.headerTitle</Trans>
          </h3>
          <div slot="body" className={styles.sideModalBody}>
            <div className={styles.fieldContainer}>
              <WppTypography type="s-strong">
                <Trans>changeLog.filter.label.categories</Trans>
              </WppTypography>
              <WppSelect
                data-testid="categories-change"
                onWppChange={(event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
                  dispatch(setFilterFormFields(event.detail.value))
                  setFormData((prevState: ISelectedFilter) => ({
                    ...prevState,
                    selectedCategories: event.detail.value,
                  }))
                }}
                placeholder={t('changeLog.filter.placeholder.categories')}
                value={formData.selectedCategories}
                withSearch
                withFolder
                type="multiple"
              >
                {filterData.categories.map((category: ICategory) => (
                  <WppListItem value={category} key={category.id}>
                    <p slot="label">{category.name}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            </div>
            <div className={styles.fieldContainer}>
              <WppTypography type="s-strong">
                <Trans>changeLog.filter.label.formFields</Trans>
              </WppTypography>
              <WppSelect
                data-testid="form-fields-change"
                onWppChange={(event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
                  setFormData((prevState: ISelectedFilter) => ({
                    ...prevState,
                    selectedFormFields: event.detail.value,
                  }))
                }}
                disabled={_.isEmpty(formData.selectedCategories)}
                placeholder={t('changeLog.filter.placeholder.formFields')}
                value={formData.selectedFormFields}
                withSearch
                withFolder
                type="multiple"
              >
                {filterData.fields.map((field: IField) => (
                  <WppListItem value={field} key={field.id}>
                    <p slot="label">{field.fieldConfig.text}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            </div>
            <div className={styles.fieldContainer}>
              <WppTypography type="s-strong">
                <Trans>changeLog.filter.label.userNames</Trans>
              </WppTypography>
              <FormFieldUser
                field={userFieldConfig}
                handleChange={(event: IFieldChangeEvent) => {
                  setFormData((prevState: ISelectedFilter) => ({
                    ...prevState,
                    selectedUserField: {
                      ...prevState.selectedUserField,
                      value: event.value,
                    },
                  }))
                }}
              />
            </div>
          </div>
          <div slot="actions" className={styles.actions}>
            <WppActionButton data-testid="btn-chg-log-filter-clearAll" variant="secondary">
              <Trans>app.button.clearAll</Trans>
            </WppActionButton>
            <div className={styles.btnActionContainer}>
              <WppButton
                data-testid="btn-chg-log-filter-cancel"
                variant="secondary"
                onClick={() => setFilterModal(false)}
              >
                <Trans>app.button.cancel</Trans>
              </WppButton>
              <WppButton
                data-testid="btn-chg-log-filter-apply"
                onClick={() => {
                  dispatch(setSelectedFilterData(formData))
                }}
              >
                <Trans>app.button.apply</Trans>
              </WppButton>
            </div>
          </div>
        </WppSideModal>,
        document.body,
      )}
    </>
  )
}

export default SideModalFilter
