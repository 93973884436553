// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KXwOZ{color:var(--wpp-danger-color-500)}.HBp38{margin-top:8px}`, "",{"version":3,"sources":["webpack://./src/app/components/form/formBuilder/formField/actions/Style.module.scss"],"names":[],"mappings":"AAAA,OACE,iCAAA,CAGF,OACE,cAAA","sourcesContent":[".btnRemove {\n  color: var(--wpp-danger-color-500);\n}\n\n.btnAdd {\n  margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnRemove": `KXwOZ`,
	"btnAdd": `HBp38`
};
export default ___CSS_LOADER_EXPORT___;
