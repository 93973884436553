import * as _ from 'lodash'
import React, { Fragment } from 'react'

import styles from 'app/components/form/formBuilder/FormBuilder.module.scss'
import FormField from 'app/components/form/formBuilder/formField'
import FormFieldTooltip from 'app/components/form/formField/formFieldTooltip'
import { WppGrid, WppTypography } from 'buildingBlocks'
import IFieldComment from 'interfaces/field/fieldComment/IFieldComment'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import IForm from 'interfaces/form/IForm'

interface IFormBuilderProps {
  /** IForm[] */
  forms: IForm[]
  /** Handle input change event. It has InputFieldChangeEvent object which have IField, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
  /** Handle field comment */
  handleComment: (field: IField, value: string) => void
  /** Form Field comments */
  setFormFieldComments: (field: IField, fieldComment: IFieldComment) => void
}

/**
 * Build form
 * @param {object} props Component props
 * @param {IForm} props.form Form object
 * @param {(event: IFieldChangeEvent) => void} props.handleChange handle input field change event
 * @param {(field: IField, value: string) => void} props.handleComment Handle field comment
 * @param {(field: IField, fieldComment: IFieldComment) => void} props.setFormFieldComments
 */
const FormBuilder: React.FC<IFormBuilderProps> = (props: IFormBuilderProps): React.ReactElement => {
  return (
    <>
      {props.forms.map((form: IForm) => {
        const formDescription = form.description ?? ''
        return (
          <Fragment key={form.id}>
            {!_.isEmpty(form.name) && (
              <WppGrid container fullWidth>
                <WppGrid item xl={12} xxl={12} sm={24} md={16} all={22}>
                  <div className={styles.form}>
                    <WppTypography type="m-strong" tag="p">
                      {form.name}
                    </WppTypography>
                    <div>
                      <FormFieldTooltip helpModal={form.helpModal} tooltip={form.tooltip ?? ''} />
                    </div>
                  </div>
                  {!_.isEmpty(formDescription) && (
                    <WppTypography type="s-body" tag="p">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: formDescription,
                        }}
                      />
                    </WppTypography>
                  )}
                </WppGrid>
              </WppGrid>
            )}
            <WppGrid container fullWidth>
              <FormField {...props} form={form} />
            </WppGrid>
          </Fragment>
        )
      })}
    </>
  )
}

export default FormBuilder
