import { WppTypography } from '@platform-ui-kit/components-library-react'
import _ from 'lodash'
import React from 'react'

import styles from 'buildingBlocks/table/tableHeader/TableHeader.module.scss'
import TableSort from 'buildingBlocks/table/tableHeader/tableSort'
import { ORDER_BY } from 'config/enums'
import ITable from 'interfaces/table/ITable'
import ITableHeader from 'interfaces/table/ITableHeader'

interface ITableHeaderProps {
  table: ITable
  onColumnOrderChange: (orderBy: ORDER_BY, orderByColumn: string) => void
}

/**
 * Table header component
 * @param {object} props
 * @param {ITable} props.table
 * @param {Function} props.onColumnOrderChange
 */
const TableHeader: React.FC<ITableHeaderProps> = ({
  onColumnOrderChange,
  table,
}: ITableHeaderProps): React.ReactElement => {
  const { colDefs, orderBy, loading } = table
  return (
    <thead className={styles.tableHead}>
      <tr>
        {colDefs.map((column: ITableHeader) => {
          return (
            <th
              style={{ width: column.width ?? 'auto', minWidth: column.minWidth ?? 'auto' }}
              key={column.id}
              className={styles.headerCell}
            >
              <button
                className={styles.headerContainer}
                onClick={() => {
                  if (!column.enableOrderBy) return
                  onColumnOrderChange(_.isEqual(orderBy, ORDER_BY.ASC) ? ORDER_BY.DESC : ORDER_BY.ASC, column.id)
                }}
                style={{
                  cursor: loading ? 'not-allowed' : 'pointer',
                }}
                disabled={loading}
                data-testid={`btn-sort-${column.id}`}
              >
                <WppTypography tag="span" type="s-strong">
                  {column.name}
                </WppTypography>
                {column.enableOrderBy && <TableSort column={column.id} table={table} />}
              </button>
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

export default TableHeader
