// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jYsZW{display:flex;height:100%;justify-content:center;align-items:center;flex-direction:column}.m3cxj{display:flex;gap:24px;flex-direction:column;align-items:center}.W0aOf{display:flex;align-items:center;gap:8px;flex-direction:column}.r9p7G{text-align:center}`, "",{"version":3,"sources":["webpack://./src/buildingBlocks/notFound/NotFound.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,WAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CAGF,OACE,YAAA,CACA,QAAA,CACA,qBAAA,CACA,kBAAA,CAGF,OACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,qBAAA,CAGF,OACE,iBAAA","sourcesContent":[".container {\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.items {\n  display: flex;\n  gap: 24px;\n  flex-direction: column;\n  align-items: center;\n}\n\n.detailsContainer {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  flex-direction: column;\n}\n\n.description {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `jYsZW`,
	"items": `m3cxj`,
	"detailsContainer": `W0aOf`,
	"description": `r9p7G`
};
export default ___CSS_LOADER_EXPORT___;
