import React from 'react'
import { useSelector } from 'react-redux'

import styles from 'app/components/header/Header.module.scss'
import { WppDivider, WppGrid } from 'buildingBlocks'
import { RootState } from 'store'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'

interface IHeaderProps {
  component: React.ReactNode
}

/**
 * App header component
 * @param {object} props
 * @param {React.ReactNode} props.component
 */
const Header: React.FC<IHeaderProps> = ({ component }: IHeaderProps): React.ReactElement => {
  const { app } = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)

  if (!app) return <></>

  return (
    <div className={styles.headerContainer}>
      <WppGrid container>{component}</WppGrid>
      <WppDivider className={styles.divider} />
    </div>
  )
}

export default Header
