import React from 'react'
import { useSelector } from 'react-redux'

import styles from 'app/components/category/Category.module.scss'
import { WppStep } from 'buildingBlocks'
import { STEP_ACTIONS } from 'config/enums'
import ICategory from 'interfaces/category/ICategory'
import { RootState } from 'store'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import { StepChangeEventDetail, WppStepCustomEvent } from 'utils/types'

interface ICategoryProps {
  /** Category*/
  category: ICategory
  /** Handle Next/Previous step event*/
  handleStep: (stepNumber: number, stepAction: STEP_ACTIONS, nextCategory: ICategory) => void
}

/**
 * Category component
 * @param {object} props
 * @param {ICategory} props.category
 * @param {(stepNumber: number, stepAction: STEP_ACTIONS, nextCategory: ICategory) => void} props.handleStep
 */
const Category: React.FC<ICategoryProps> = ({ handleStep, category }: ICategoryProps): React.ReactElement => {
  const { app } = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)

  if (!app) return <></>

  return (
    <WppStep
      key={category.id}
      onWppStepChange={(event: WppStepCustomEvent<StepChangeEventDetail>) => {
        const stepNumber: number = event.detail.index ?? 1
        handleStep(stepNumber, STEP_ACTIONS.STEP, category)
      }}
      className={styles.categoryStep}
      completed={category.isValid && category.touched}
      error={!category.isValid}
    >
      <p slot="label">{category.name}</p>
    </WppStep>
  )
}

export default Category
