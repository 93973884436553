// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.G1avL{background-color:var(--wpp-white-color);box-shadow:0px 0px 1px rgba(52,58,63,.1),0px 1px 5px rgba(52,58,63,.1);border-radius:8px;padding:24px 32px;margin:1px}.G1avL .cwxvo{display:flex;align-items:center;justify-content:space-between}`, "",{"version":3,"sources":["webpack://./src/pages/changeLog/ChangeLog.module.scss"],"names":[],"mappings":"AAAA,OACE,uCAAA,CACA,sEAAA,CACA,iBAAA,CACA,iBAAA,CACA,UAAA,CAEA,cACE,YAAA,CACA,kBAAA,CACA,6BAAA","sourcesContent":[".container {\n  background-color: var(--wpp-white-color);\n  box-shadow: 0px 0px 1px rgba(52, 58, 63, 0.1), 0px 1px 5px rgba(52, 58, 63, 0.1);\n  border-radius: 8px;\n  padding: 24px 32px;\n  margin: 1px;\n\n  .filterContainer {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `G1avL`,
	"filterContainer": `cwxvo`
};
export default ___CSS_LOADER_EXPORT___;
