import { WppSkeleton } from '@platform-ui-kit/components-library-react'
import _ from 'lodash'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import styles from 'buildingBlocks/table/tableBody/TableBody.module.scss'
import ITableHeader from 'interfaces/table/ITableHeader'

interface ITableHeaderProps {
  colDefs: ITableHeader[]
  itemsPerPage: number
}

/**
 * Table header component
 * @param {object} props
 * @param {ITableHeader[]} props.colDefs
 * @param {boolean} props.loading
 */
const TableLoader: React.FC<ITableHeaderProps> = ({ colDefs, itemsPerPage }: ITableHeaderProps): React.ReactElement => {
  return (
    <tbody>
      {_.times(itemsPerPage).map(() => {
        return (
          <tr key={uuidv4()} className={styles.headerRow}>
            {colDefs.map((column: ITableHeader) => {
              return (
                <th style={{ width: column.minWidth ?? 'auto' }} key={column.id} className={styles.tableCell}>
                  <WppSkeleton height={30} />
                </th>
              )
            })}
          </tr>
        )
      })}
    </tbody>
  )
}

export default TableLoader
