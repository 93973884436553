import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import styles from 'app/components/action/menu/Menu.module.scss'
import CancelReview from 'app/components/review/cancelReview'
import Reviewers from 'app/components/review/reviewers'
import {
  WppIconButton,
  WppIconCopy,
  WppIconEye,
  WppIconMore,
  WppListItem,
  WppMenuContext,
  WppTypography,
} from 'buildingBlocks'
import { TOAST_MESSAGE_TYPES } from 'config/enums'
import { AppDispatch, RootState } from 'store'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import { showAlert } from 'store/reducers/alertSlice'

/**
 * Action menu items
 */
const Menu: React.FC = (): React.ReactElement => {
  const projectBriefState = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const onCopyToClipboard = (message: string): void => {
    dispatch(
      showAlert({
        message,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    )
  }

  return (
    <WppMenuContext>
      <WppIconButton slot="trigger-element">
        <WppIconMore size="s" />
      </WppIconButton>
      <div>
        <Reviewers />
        <CancelReview />
        <WppListItem onWppChangeListItem={() => navigate('/change-log')}>
          <p slot="label" className={styles.menuItem}>
            <WppIconEye />
            <WppTypography tag="span" type="s-body">
              <Trans>action.menu.changeLog</Trans>
            </WppTypography>
          </p>
        </WppListItem>
        <WppListItem>
          <p slot="label" className={styles.menuItem}>
            <WppIconCopy />
            <CopyToClipboard
              text={window.location.href}
              onCopy={() => onCopyToClipboard(t('action.alertMessage.copyBriefLaunchUrl'))}
            >
              <WppTypography tag="span" type="s-body">
                <Trans>action.menu.copyBriefLaunchUrl</Trans>
              </WppTypography>
            </CopyToClipboard>
          </p>
        </WppListItem>
        <WppListItem>
          <p slot="label" className={styles.menuItem}>
            <WppIconCopy />
            <CopyToClipboard
              text={projectBriefState.questionnaire.projectQuestionnaireId}
              onCopy={() => onCopyToClipboard(t('action.alertMessage.copyBriefIDNumber'))}
            >
              <WppTypography tag="span" type="s-body">
                <Trans>action.menu.copyBriefIDNumber</Trans>
              </WppTypography>
            </CopyToClipboard>
          </p>
        </WppListItem>
      </div>
    </WppMenuContext>
  )
}

export default Menu
