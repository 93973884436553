import { apiConfig, COMMENTS_PER_PAGE } from 'config/constants'
import { API_TYPES, ORDER_BY } from 'config/enums'

const QUESTIONNAIRE_API: string = apiConfig[API_TYPES.QUESTIONNAIRE_API]
const PROJECT_API: string = apiConfig[API_TYPES.PROJECT_API]
const MASTER_API: string = apiConfig[API_TYPES.MASTER_API]
const FACADE_API: string = apiConfig[API_TYPES.FACADE_API]

export const saveFormDataAPIPath = (projectQuestionnaireId: string) =>
  `${apiConfig[API_TYPES.QUESTIONNAIRE_API]}/project-questionnaires/${projectQuestionnaireId}/responses`

export const getQuestionnaireAPIPath = (project_questionnaire_id: string) =>
  `${apiConfig[API_TYPES.QUESTIONNAIRE_API]}/project-questionnaires/${project_questionnaire_id}/content`

export const getQuestionnairesAPIPath = (tenantId: string): string => `${QUESTIONNAIRE_API}/questionnaire/${tenantId}`

export const getQuestionnaireByIdAPIPath = (id: string): string => `${QUESTIONNAIRE_API}/questionnaires/${id}`

export const getSelectedQuestionnaireAPIPath = (projectId: string, itemId: string): string =>
  `${QUESTIONNAIRE_API}/project-questionnaire-with-approval?project_id=${projectId}&item_id=${itemId}`

export const getParentQuestionnairesAPIPath = (parentQuestionnaireId: string, projectId: string): string =>
  `${QUESTIONNAIRE_API}/project-questionnaires-with-approval?questionnaireId=${parentQuestionnaireId}&projectId=${projectId}&isParent=true`

export const getProjectAppsAPIPath = (projectId: string): string => `${PROJECT_API}/projects/${projectId}/tasks/search`

export const getUploadSignedAPIPath = (): string => `${PROJECT_API}/files/generate-temp-upload-urls`

export const getProjectMemberAPIPath = (projectId: string): string => `${PROJECT_API}/projects/${projectId}`

export const getUploadFileAPIPath = (projectId: string): string => `${PROJECT_API}/projects/${projectId}/files`

export const getQuestionnaireReviewAPIPath = (projectQuestionnaireId: string) =>
  `${QUESTIONNAIRE_API}/project-questionnaires/${projectQuestionnaireId}/approval`

export const sendToReviewAPIPath = (projectQuestionnaireId: string) =>
  `${QUESTIONNAIRE_API}/project-questionnaires/${projectQuestionnaireId}/approval`

export const updateReviewAPIPath = (projectQuestionnaireId: string) =>
  `${QUESTIONNAIRE_API}/project-questionnaires/${projectQuestionnaireId}/approval/reviews/me`

export const deleteReviewAPIPath = (projectQuestionnaireId: string) =>
  `${QUESTIONNAIRE_API}/project-questionnaires/${projectQuestionnaireId}/approval`

export const getMarketsAPIPath = () => `${MASTER_API}/markets?page=1&itemsPerPage=1000`

export const updateProjectQuestionnaireAPIPath = (projectQuestionnaireId: string) =>
  `${apiConfig[API_TYPES.QUESTIONNAIRE_API]}/project-questionnaire/${projectQuestionnaireId}`

export const createProjectQuestionnaireAPIPath = () =>
  `${apiConfig[API_TYPES.QUESTIONNAIRE_API]}/project-questionnaires`

export const addCommentAPIPath = () => `${apiConfig[API_TYPES.QUESTIONNAIRE_API]}/comments`

export const getMoreCommentsByStartDateAPIPath = (
  projectQuestionnaireId: string,
  formFieldId: string,
  startDate: string,
  page: number,
) =>
  `${
    apiConfig[API_TYPES.QUESTIONNAIRE_API]
  }/project-questionnaires/${projectQuestionnaireId}/comments?formFieldId=${formFieldId}&createdAt%5Blte%5D=${startDate}&page=${page}&itemsPerPage=${COMMENTS_PER_PAGE}&sortOrder=desc`

export const getCommentUniqueUsersAPIPath = (projectQuestionnaireId: string, categoryId: string) =>
  `${
    apiConfig[API_TYPES.QUESTIONNAIRE_API]
  }/project-questionnaires/${projectQuestionnaireId}/comments/users?categoryId=${categoryId}&partitionBy=formFieldId`

export const getUserAPIPath = () => `${FACADE_API}/users`

export const getReviewerInfoAPIPath = (projectQuestionnaireId: string) =>
  `${apiConfig[API_TYPES.QUESTIONNAIRE_API]}/project-questionnaires/${projectQuestionnaireId}/approval/reviews/me`

export const getReviewerDataAPIPath = (projectQuestionnaireId: string) =>
  `${apiConfig[API_TYPES.QUESTIONNAIRE_API]}/project-questionnaires/${projectQuestionnaireId}/approval/reviews`

export const getChangeLogDataAPIPath = (projectQuestionnaireId: string, page: number, sortOrder: ORDER_BY) =>
  `${
    apiConfig[API_TYPES.QUESTIONNAIRE_API]
  }/project-questionnaires/${projectQuestionnaireId}/responses-with-change?page=${page}&itemsPerPage=10&sortOrder=${sortOrder}`
