// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ecCZG{padding:13px 16px;text-align:left;border-bottom:1px solid var(--wpp-grey-color-300)}.ecCZG .BpgFv{font-size:14px;font-family:inherit;font-weight:400}.UxWnX:hover{background-color:var(--wpp-grey-color-100)}`, "",{"version":3,"sources":["webpack://./src/buildingBlocks/table/tableBody/TableBody.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,eAAA,CACA,iDAAA,CAEA,cACE,cAAA,CACA,mBAAA,CACA,eAAA,CAKF,aACE,0CAAA","sourcesContent":[".tableCell {\n  padding: 13px 16px;\n  text-align: left;\n  border-bottom: 1px solid var(--wpp-grey-color-300);\n\n  .text {\n    font-size: 14px;\n    font-family: inherit;\n    font-weight: 400;\n  }\n}\n\n.tableRow {\n  &:hover {\n    background-color: var(--wpp-grey-color-100);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableCell": `ecCZG`,
	"text": `BpgFv`,
	"tableRow": `UxWnX`
};
export default ___CSS_LOADER_EXPORT___;
