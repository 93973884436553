import React from 'react'

import styles from 'buildingBlocks/table/Table.module.scss'
import TableBody from 'buildingBlocks/table/tableBody'
import TableHeader from 'buildingBlocks/table/tableHeader'
import { ORDER_BY } from 'config/enums'
import ITable from 'interfaces/table/ITable'

interface ITableProps {
  table: ITable
  onColumnOrderChange: (orderBy: ORDER_BY, orderByColumn: string) => void
}

/**
 * Table component
 * @param {object} props
 * @param {ITable} props.table
 * @param {Function} props.onColumnOrderChange
 */
const Table: React.FC<ITableProps> = ({ table, onColumnOrderChange }: ITableProps): React.ReactElement => {
  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <TableHeader table={table} onColumnOrderChange={onColumnOrderChange} />
        <TableBody table={table} />
      </table>
    </div>
  )
}

export default Table
