import { AvatarSize } from '@platform-ui-kit/components-library'
import { WppAvatar, WppTypography } from '@platform-ui-kit/components-library-react'
import React from 'react'

import styles from 'buildingBlocks/userAvatar/UserAvatar.module.scss'

interface IUserAvatarProps {
  firstName: string
  lastName: string
  email: string
  avatarUrl: string | null
  avatarSize: AvatarSize
}

const UserAvatar: React.FC<IUserAvatarProps> = ({
  firstName,
  lastName,
  email,
  avatarUrl,
  avatarSize,
}: IUserAvatarProps): React.ReactElement => {
  return (
    <span className={styles.container}>
      <WppAvatar
        className={styles.userAvatar}
        size={avatarSize}
        name={`${firstName} ${lastName}`}
        src={avatarUrl ?? ''}
      />
      <span className={styles.userInfo}>
        <WppTypography tag="span" type="s-body">
          {firstName} {lastName}
        </WppTypography>
        <WppTypography tag="span" type="xs-body">
          {email}
        </WppTypography>
      </span>
    </span>
  )
}

export default UserAvatar
