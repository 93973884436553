import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { FIELD_TYPES, FIELD_ORIENTATION } from 'config/enums'
import IField from 'interfaces/field/IField'
import IRepeatableField from 'interfaces/field/repeatable/IRepeatableField'
import IResponseField from 'interfaces/field/response/IResponseField'

export default class FormFieldValidation {
  /**
   * Validate a field
   * @param {IField} field
   * @returns {IField} field
   */
  validateField(field: IField): IField {
    if (field.isHidden || field.disabled) {
      return field
    }

    let updatedField = this.validate(field, field.value)
    let isValid = updatedField.isValid
    let errorMessage = updatedField.errorMessage

    let repeatableFields: IRepeatableField[] = []
    if (_.gt(field.maxRepeatableAmount, 0)) {
      isValid = true
      for (let repeatableField of field.repeatableFields) {
        let rField = this.validate(field, repeatableField.value)
        isValid = isValid && rField.isValid
        errorMessage = rField.errorMessage
        repeatableFields.push({ ...repeatableField, touched: true, isValid: rField.isValid, errorMessage })
      }
    }

    return {
      ...updatedField,
      isValid,
      messageType: !isValid ? 'error' : undefined,
      repeatableFields,
      errorMessage,
    }
  }

  /**
   * Validate value selection
   * @param {IField} field
   * @param {any} value
   * @returns {IField}
   */
  validateValueSelection(field: IField, value: any): IField {
    let fieldValue = value ?? []
    const { valueAmount } = field.fieldConfig
    if (!valueAmount) {
      return field
    }

    let errorMessage = ''
    let isValid = field.isValid

    if (_.gt(valueAmount.min, 0) && _.lt(fieldValue.length, valueAmount.min)) {
      errorMessage = `Please select minimum ${valueAmount.min} option`
      isValid = false
    }
    if (_.gt(valueAmount.max, 0) && _.gt(fieldValue.length, valueAmount.max)) {
      errorMessage = `Please select maximum ${valueAmount.max} option`
      isValid = false
    }
    return {
      ...field,
      errorMessage,
      isValid,
    }
  }

  /**
   * Validate a field
   * @param {IField} field
   * @param {any} value Updated value of the field
   * @returns {IField}
   */
  validate(field: IField, value: any): IField {
    let isValid = true

    let errorMessage = ''
    if (_.isEqual(field.type, FIELD_TYPES.TEXT_AREA)) {
      isValid = this.validateCharacterLimit(field, value)
    }

    field = {
      ...field,
      isValid,
      errorMessage,
    }
    if (field.fieldConfig.multi && field.fieldConfig.valueAmount) {
      field = this.validateValueSelection(field, value)
      isValid = isValid && field.isValid
      errorMessage = field.errorMessage
    }

    if (field.isRequired) {
      let isEmptyField = FormFieldValidation.isEmptyField({ ...field, value })
      isValid = isValid && !isEmptyField
      errorMessage = !isEmptyField ? field.errorMessage : 'Field is required.'
    }

    field = {
      ...field,
      isValid,
      errorMessage,
    }

    return field
  }

  getValidCurrencyValue = (inputValue: any) => {
    return !inputValue
      ? {
          currencyType: null,
          currencyAmount: '',
        }
      : inputValue
  }

  getValidSelectValue = (field: IResponseField, inputValue: any) => {
    if (field.config?.multi) {
      return _.isArray(inputValue) ? inputValue : []
    }
    return _.isArray(inputValue) ? null : inputValue
  }

  /**
   * Get valid repeatable field value
   * @param {IResponseField} field
   * @param {any} inputValue
   * @returns {IRepeatable}
   */
  getValidInputFieldValue = (field: IResponseField, inputValue: any) => {
    if (field.config) {
      let value = inputValue
      if (field.config?.multi) {
        return _.isArray(inputValue) ? inputValue : []
      }
      return _.isArray(value) ? value : []
    }
    return inputValue
  }

  /**
   * Get valid repeatable field value
   * @param {IResponseField} field
   * @param {any} inputValue
   * @returns {IRepeatable}
   */
  getValidRepeatableField = (field: IResponseField, inputValue: any): IRepeatableField[] => {
    if (!!inputValue && inputValue.hasOwnProperty('repeatable')) {
      return inputValue.repeatable.map((repeatableField: IRepeatableField) => {
        const fieldValue = this.getValidValue(field, repeatableField.value)

        return {
          ...repeatableField,
          value: this.getValidValue(field, repeatableField.value),
          isValid: true,
          touched: false,
          isCompleted: !_.isEmpty(_.toString(fieldValue).trim()),
        }
      })
    }

    const fieldValue = this.getValidValue(field, inputValue)

    return [
      {
        id: uuidv4(),
        value: fieldValue,
        order: 1,
        isValid: false,
        touched: false,
        errorMessage: '',
        isCompleted: !_.isEmpty(_.toString(fieldValue).trim()),
      },
    ]
  }

  /**
   * Get valid value
   * @param {IField} field
   * @returns {any}
   */
  getValidValue(field: IResponseField, inputValue: any): any {
    let value = inputValue
    switch (field.fieldType) {
      case FIELD_TYPES.TEXT_INPUT:
      case FIELD_TYPES.DATE_PICKER:
      case FIELD_TYPES.TEXT_AREA: {
        value = _.isString(inputValue) ? inputValue : ''
        break
      }
      case FIELD_TYPES.RADIO: {
        value = !inputValue ? null : inputValue
        break
      }
      case FIELD_TYPES.CURRENCY: {
        value = this.getValidCurrencyValue(inputValue)
        break
      }
      // Select/Market Field
      case FIELD_TYPES.SELECT:
      case FIELD_TYPES.MARKET: {
        value = this.getValidSelectValue(field, inputValue)
        break
      }
      case FIELD_TYPES.CHECKBOX:
      case FIELD_TYPES.FILE_UPLOAD:
      case FIELD_TYPES.AUTOCOMPLETE:
      case FIELD_TYPES.USER: {
        value = this.getValidInputFieldValue(field, inputValue)
        break
      }
    }
    return value
  }

  /**
   * Validate Character limit
   * @param {IField} field
   * @param {any} value
   * @returns {boolean}
   */
  validateCharacterLimit(field: IField, value: any): boolean {
    if (!value) {
      return true
    }
    if (!field.fieldConfig.charactersLimit) {
      return true
    }

    return _.lte(value.length, field.fieldConfig.charactersLimit)
  }

  /**
   * Validate field configuration
  //  * @param {IField} field
   * @returns {IField} field
   */
  validateFieldConfiguration(field: IField): IField {
    let fieldData: IField = _.cloneDeep(field)
    const { fieldConfig } = fieldData

    switch (field.type) {
      case FIELD_TYPES.TEXT_INPUT:
      case FIELD_TYPES.TEXT_AREA:
      case FIELD_TYPES.DATE_PICKER: {
        fieldData.value = _.isString(fieldData.value) ? fieldData.value : ''
        break
      }
      case FIELD_TYPES.CHECKBOX:
      case FIELD_TYPES.AUTOCOMPLETE:
      case FIELD_TYPES.USER: {
        fieldData.value = _.isArray(fieldData.value) ? fieldData.value : []
        fieldData.fieldConfig.options = _.isArray(fieldConfig.options) ? fieldData.fieldConfig.options : []
        break
      }
      case FIELD_TYPES.FILE_UPLOAD: {
        fieldData.value = _.isArray(fieldData.value) ? fieldData.value : []
        break
      }
    }

    fieldData.fieldConfig.orientation = !fieldConfig.orientation ? FIELD_ORIENTATION.VERTICAL : fieldConfig.orientation
    if (_.gt(field.maxRepeatableAmount, 0)) {
      fieldData.value = field.value
    }

    return fieldData
  }

  /**
   * Validate if field is empty
   * @param {IField} field
   * @returns {boolean} field
   */
  public static isEmptyField(field: IField): boolean {
    let isFieldEmpty: boolean = false

    const Value = field.value ?? ''

    switch (field.type) {
      case FIELD_TYPES.TEXT_INPUT:
      case FIELD_TYPES.DATE_PICKER:
      case FIELD_TYPES.NUMBER:
      case FIELD_TYPES.RADIO:
      case FIELD_TYPES.TEXT_AREA: {
        isFieldEmpty = _.isEmpty(_.toString(Value).trim())
        break
      }
      case FIELD_TYPES.CURRENCY: {
        isFieldEmpty = _.isNull(Value.currencyType) || _.isEmpty(_.toString(Value.currencyAmount).trim())
        break
      }
      case FIELD_TYPES.SELECT:
      case FIELD_TYPES.MARKET: {
        if (field.fieldConfig.multi) {
          isFieldEmpty = _.isEmpty(Value)
        } else {
          isFieldEmpty = _.isEmpty(_.toString(Value))
        }
        break
      }
      case FIELD_TYPES.CHECKBOX:
      case FIELD_TYPES.AUTOCOMPLETE:
      case FIELD_TYPES.USER:
      case FIELD_TYPES.FILE_UPLOAD: {
        isFieldEmpty = _.isArray(field.value) ? _.isEmpty(field.value) : false
        break
      }
    }

    return isFieldEmpty
  }
}
