export enum FIELD_TYPES {
  COUNTER = 'COUNTER',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  TEXT_INPUT = 'TEXT_INPUT',
  TEXT_AREA = 'TEXT_AREA',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  MARKET = 'MARKET',
  USER = 'USER',
  FILE_UPLOAD = 'FILE_UPLOAD',
  SELECT = 'SELECT',
  DATE_PICKER = 'DATE_PICKER',
  CURRENCY = 'CURRENCY',
  NUMBER = 'NUMBER',
  USER_MENTION = 'USER_MENTION',
}

export enum REVIEW_RESPONSE_TYPES {
  REJECTION = 'REJECTION',
  APPROVAL = 'APPROVAL',
  PENDING = 'PENDING',
}

export enum API_TYPES {
  QUESTIONNAIRE_API = 'QUESTIONNAIRE_API',
  PROJECT_API = 'PROJECT_API',
  APP_NAMESPACE = 'APP_NAMESPACE',
  FACADE_API = 'FACADE_API',
  MASTER_API = 'MASTER_API',
}

export enum REVIEW_ACTION_TYPES {
  SEND_TO_REVIEW = 'SEND_TO_REVIEW',
  APPROVE_REJECT_ACTION = 'APPROVE_REJECT_ACTION',
  CANCEL_REVIEW = 'CANCEL_REVIEW',
}

export enum REVIEW_TYPES {
  ALL_REVIEWS = 'ALL_REVIEWS',
  MAJORITY_OF_REVIEWS = 'MAJORITY_OF_REVIEWS',
  ANY_REVIEW = 'ANY_REVIEW',
}

// Questionnaire status variant
export enum TAG_VARIANTS {
  NEUTRAL = 'neutral',
  WARNING = 'warning',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

// Order by options
export enum ORDER_BY {
  ASC = 'asc',
  DESC = 'desc',
}

// App actions
export enum APP_ACTIONS {
  SAVE_PROGRESS = 'save_progress',
  SAVE_AND_EXIT = 'save_and_exit',
  PDF_VIEWER = 'PDF_VIEWER',
  CANCEL = 'cancel',
}

// Stepper action types
export enum STEP_ACTIONS {
  PREVIOUS = 'previous',
  NEXT = 'next',
  STEP = 'step',
}

// This is used in Checkbox and Radio button
export enum FIELD_ORIENTATION {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

// Project roles
export enum PROJECT_ROLES {
  OWNER = 'OWNER',
  VIEWER = 'VIEWER',
  CONTRIBUTOR = 'CONTRIBUTOR',
}

// Questionnaire Status Values
export enum QUESTIONNAIRE_STATUS_VALUES {
  DRAFT = 'Draft',
  SENT_FOR_APPROVAL = 'Sent to review',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

// Questionnaire Status
export enum QUESTIONNAIRE_STATUS {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
}

// Review Status
export enum REVIEW_STATUS {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

// Alert messages
export enum TOAST_MESSAGE_TYPES {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFORMATION = 'information',
}

export enum CURRENCY_TYPES {
  AED = 'UAE Dirham',
  AFN = 'Afghan Afghani',
  ALL = 'Albanian Lek',
  AMD = 'Armenian Dram',
  ANG = 'Netherlands Antillian Guilder',
  AOA = 'Angolan Kwanza',
  ARS = 'Argentine Peso',
  AUD = 'Australian Dollar',
  AWG = 'Aruban Florin',
  AZN = 'Azerbaijani Manat',
  BAM = 'Bosnia and Herzegovina Mark',
  BBD = 'Barbados Dollar',
  BDT = 'Bangladeshi Taka',
  BGN = 'Bulgarian Lev',
  BHD = 'Bahraini Dinar',
  BIF = 'Burundian Franc',
  BMD = 'Bermudian Dollar',
  BND = 'Brunei Dollar',
  BOB = 'Bolivian Boliviano',
  BRL = 'Brazilian Real',
  BSD = 'Bahamian Dollar',
  BTN = 'Bhutanese Ngultrum',
  BWP = 'Botswana Pula',
  BYN = 'Belarusian Ruble',
  BZD = 'Belize Dollar',
  CAD = 'Canadian Dollar',
  CDF = 'Congolese Franc',
  CHF = 'Swiss Franc',
  CLP = 'Chilean Peso',
  CNY = 'Chinese Renminbi',
  COP = 'Colombian Peso',
  CRC = 'Costa Rican Colon',
  CUP = 'Cuban Peso',
  CVE = 'Cape Verdean Escudo',
  CZK = 'Czech Koruna',
  DJF = 'Djiboutian Franc',
  DKK = 'Danish Krone',
  DOP = 'Dominican Peso',
  DZD = 'Algerian Dinar',
  EGP = 'Egyptian Pound',
  ERN = 'Eritrean Nakfa',
  ETB = 'Ethiopian Birr',
  EUR = 'Euro',
  FJD = 'Fiji Dollar',
  FKP = 'Falkland Islands Pound',
  FOK = 'Faroese Króna',
  GBP = 'Pound Sterling',
  GEL = 'Georgian Lari',
  GGP = 'Guernsey Pound',
  GHS = 'Ghanaian Cedi',
  GIP = 'Gibraltar Pound',
  GMD = 'Gambian Dalasi',
  GNF = 'Guinean Franc',
  GTQ = 'Guatemalan Quetzal',
  GYD = 'Guyanese Dollar',
  HKD = 'Hong Kong Dollar',
  HNL = 'Honduran Lempira',
  HRK = 'Croatian Kuna',
  HTG = 'Haitian Gourde',
  HUF = 'Hungarian Forint',
  IDR = 'Indonesian Rupiah',
  ILS = 'Israeli New Shekel',
  IMP = 'Manx Pound',
  INR = 'Indian Rupee',
  IQD = 'Iraqi Dinar',
  IRR = 'Iranian Rial',
  ISK = 'Icelandic Króna',
  JEP = 'Jersey Pound',
  JMD = 'Jamaican Dollar',
  JOD = 'Jordanian Dinar',
  JPY = 'Japanese Yen',
  KES = 'Kenyan Shilling',
  KGS = 'Kyrgyzstani Som',
  KHR = 'Cambodian Riel',
  KID = 'Kiribati Dollar',
  KMF = 'Comorian Franc',
  KRW = 'South Korean Won',
  KWD = 'Kuwaiti Dinar',
  KYD = 'Cayman Islands Dollar',
  KZT = 'Kazakhstani Tenge',
  LAK = 'Lao Kip',
  LBP = 'Lebanese Pound',
  LKR = 'Sri Lanka Rupee',
  LRD = 'Liberian Dollar',
  LSL = 'Lesotho Loti',
  LYD = 'Libyan Dinar',
  MAD = 'Moroccan Dirham',
  MDL = 'Moldovan Leu',
  MGA = 'Malagasy Ariary',
  MKD = 'Macedonian Denar',
  MMK = 'Burmese Kyat',
  MNT = 'Mongolian Tögrög',
  MOP = 'Macanese Pataca',
  MRU = 'Mauritanian Ouguiya',
  MUR = 'Mauritian Rupee',
  MVR = 'Maldivian Rufiyaa',
  MWK = 'Malawian Kwacha',
  MXN = 'Mexican Peso',
  MYR = 'Malaysian Ringgit',
  MZN = 'Mozambican Metical',
  NAD = 'Namibian Dollar',
  NGN = 'Nigerian Naira',
  NIO = 'Nicaraguan Córdoba',
  NOK = 'Norwegian Krone',
  NPR = 'Nepalese Rupee',
  NZD = 'New Zealand Dollar',
  OMR = 'Omani Rial',
  PAB = 'Panamanian Balboa',
  PEN = 'Peruvian Sol',
  PGK = 'Papua New Guinean Kina',
  PHP = 'Philippine Peso',
  PKR = 'Pakistani Rupee',
  PLN = 'Polish Złoty',
  PYG = 'Paraguayan Guaraní',
  QAR = 'Qatari Riyal',
  RON = 'Romanian Leu',
  RSD = 'Serbian Dinar',
  RUB = 'Russian Ruble',
  RWF = 'Rwandan Franc',
  SAR = 'Saudi Riyal',
  SBD = 'Solomon Islands Dollar',
  SCR = 'Seychellois Rupee',
  SDG = 'Sudanese Pound',
  SEK = 'Swedish Krona',
  SGD = 'Singapore Dollar',
  SHP = 'Saint Helena Pound',
  SLE = 'Sierra Leonean Leone',
  SOS = 'Somali Shilling',
  SRD = 'Surinamese Dollar',
  SSP = 'South Sudanese Pound',
  STN = 'São Tomé and Príncipe Dobra',
  SYP = 'Syrian Pound',
  SZL = 'Eswatini Lilangeni',
  THB = 'Thai Baht',
  TJS = 'Tajikistani Somoni',
  TMT = 'Turkmenistan Manat',
  TND = 'Tunisian Dinar',
  TOP = "Tongan Pa'anga",
  TRY = 'Turkish Lira',
  TTD = 'Trinidad and Tobago Dollar',
  TVD = 'Tuvaluan Dollar',
  TWD = 'New Taiwan Dollar',
  TZS = 'Tanzanian Shilling',
  UAH = 'Ukrainian Hryvnia',
  UGX = 'Ugandan Shilling',
  USD = 'United States Dollar',
  UYU = 'Uruguayan Peso',
  UZS = "Uzbekistani So'm",
  VES = 'Venezuelan Bolívar Soberano',
  VND = 'Vietnamese Đồng',
  VUV = 'Vanuatu Vatu',
  WST = 'Samoan Tālā',
  XAF = 'Central African CFA Franc',
  XCD = 'East Caribbean Dollar',
  XDR = 'Special Drawing Rights',
  XOF = 'West African CFA franc',
  XPF = 'CFP Franc',
  YER = 'Yemeni Rial',
  ZAR = 'South African Rand',
  ZMW = 'Zambian Kwacha',
  ZWL = 'Zimbabwean Dollar',
}
