import { Page, Text, View, Document, Font, Image, pdf } from '@react-pdf/renderer'
import { useOs } from '@wppopen/react'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'

import Category from 'app/components/action/pdfViewer/category'
import styles from 'app/components/action/pdfViewer/styles'
import { WppActionButton, WppIconDownload } from 'buildingBlocks'
import { PDF_FONT_FAMILY_NAME, PDF_FONTS_SRC } from 'config/constants'
import { APP_ACTIONS, TOAST_MESSAGE_TYPES } from 'config/enums'
import IApp from 'interfaces/app/IApp'
import ICategory from 'interfaces/category/ICategory'
import PDFHelper from 'utils/form/PDFHelper'

interface IPdfViewerProps {
  /** IApp interface */
  app: IApp
  projectQuestionnaireId: string
  /** Handle app Submit/Save Progress/Cancel event*/
  handleAppAction: (
    appAction: string,
    callback: (message: TOAST_MESSAGE_TYPES) => void,
    displayMessage?: boolean,
  ) => void
}

Font.register({
  family: PDF_FONT_FAMILY_NAME,
  fonts: PDF_FONTS_SRC,
})

/**
 * PDF Viewer component
 * @param {object} props
 * @param {IApp} props.app
 * @param {string} props.projectQuestionnaireId
 * @param {Function} props.handleAppAction
 */
const PdfViewer: React.FC<IPdfViewerProps> = ({
  app,
  handleAppAction,
  projectQuestionnaireId,
}: IPdfViewerProps): React.ReactElement => {
  const { osContext } = useOs()
  const [loading, setLoading] = useState<boolean>(false)
  const [logoUrl, setLogoUrl] = useState<string>('')
  const logoOriginal = osContext.tenant.logoOriginal?.url ?? ''

  const setPDFLogo = useCallback(() => {
    if (logoOriginal) {
      setLoading(true)
      PDFHelper.getBase64Image(logoOriginal, (data: string) => {
        setLogoUrl(data)
        setLoading(false)
      })
    }
  }, [logoOriginal])

  useEffect(() => {
    setPDFLogo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoOriginal])

  const onDownload = async () => {
    const blob = await pdf(
      <Document title={app.appName}>
        <Page size="A4" style={styles.page}>
          {!_.isEmpty(logoUrl) && <Image src={`data:image/png;base64,${logoUrl}`} style={styles.tenantLogo} fixed />}
          <Text style={styles.header}>{app.appName}</Text>
          <View style={styles.categoryContainer}>
            {app.categories.map((category: ICategory) => (
              <Category
                key={category.id}
                category={category}
                app={app}
                projectQuestionnaireId={projectQuestionnaireId}
              />
            ))}
          </View>
        </Page>
      </Document>,
    ).toBlob()
    const pdfUrl = URL.createObjectURL(blob)
    window.open(pdfUrl, '_blank')
  }

  const handlePdfViewer = () => {
    handleAppAction(APP_ACTIONS.PDF_VIEWER, (message: TOAST_MESSAGE_TYPES) => {
      if (!_.isEqual(message, TOAST_MESSAGE_TYPES.ERROR)) onDownload()
    })
  }

  return (
    <WppActionButton loading={loading} data-testid="btn-pdf" id="btn-pdf" onClick={handlePdfViewer}>
      <WppIconDownload color="var(--wpp-brand-color)" />
    </WppActionButton>
  )
}

export default PdfViewer
