import { useOs } from '@wppopen/react'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Header from 'app/components/header'
import { Table, WppDatepicker, WppGrid, WppTypography } from 'buildingBlocks'
import { DEFAULT_PAGINATOR_DATA } from 'config/constants'
import { ORDER_BY } from 'config/enums'
import IChangeLogData from 'interfaces/changeLog/IChangeLogData'
import styles from 'pages/changeLog/ChangeLog.module.scss'
import SideModalFilter from 'pages/changeLog/filter'
import { getColumnData, getRowData } from 'pages/changeLog/Helper'
import { AppDispatch, RootState } from 'store'
import IAppContextState from 'store/interfaces/IAppContextState'
import IChangeLogState from 'store/interfaces/IChangeLogState'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import { setDateRangeFilter, setTableData } from 'store/reducers/changeLogSlice'
import ChangeLogHelper from 'utils/changeLog/ChangeLogHelper'
import { DatePickerEventDetail, WppDatepickerCustomEvent } from 'utils/types'

const ChangeLog: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const { osApi } = useOs()
  const { t } = useTranslation()
  const { filterSelectedData, tableData } = useSelector<RootState, IChangeLogState>(
    (state: RootState) => state.changeLogState,
  )
  const projectBriefState = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)
  const questionnaireState = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)
  const appContext = useSelector<RootState, IAppContextState>((state: RootState) => state.appContext)

  const initLoad = useCallback(async () => {
    const projectQuestionnaireId = projectBriefState.questionnaire.projectQuestionnaireId
    if (questionnaireState.app && projectQuestionnaireId) {
      const logData: Array<IChangeLogData> = await ChangeLogHelper.getChangeLogData(
        osApi.getAccessToken(),
        projectQuestionnaireId,
        questionnaireState.app,
        appContext.tenantId,
        1,
        ORDER_BY.ASC,
      )
      dispatch(
        setTableData({
          colDefs: getColumnData(t),
          rowData: getRowData(logData),
          paginator: DEFAULT_PAGINATOR_DATA,
          orderBy: ORDER_BY.DESC,
          loading: false,
          orderByColumn: 'date',
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    initLoad()
  }, [initLoad])

  return (
    <>
      <Header
        component={
          <WppGrid item all={24} className={styles.header}>
            <div className={styles.headerItem}>
              <WppTypography tag="h3" type="m-strong">
                {questionnaireState.app!.appName}
              </WppTypography>
            </div>
          </WppGrid>
        }
      />
      <WppGrid container rowSpacing={2}>
        <WppGrid item all={24}>
          <WppGrid className={styles.container} fullWidth container>
            <WppGrid item all={24} className={styles.filterContainer}>
              <WppDatepicker
                size="s"
                range
                locale={{
                  dateFormat: 'MM/dd/yyyy',
                }}
                maxDate={moment().format('MM/DD/YYYY')}
                value={filterSelectedData.dateRange}
                onWppChange={(event: WppDatepickerCustomEvent<DatePickerEventDetail>) => {
                  const dateRange: any = event.detail.formattedDate
                  if (_.isArray(dateRange)) {
                    dispatch(setDateRangeFilter(dateRange))
                  }
                }}
                onWppDateClear={() => {
                  dispatch(setDateRangeFilter([]))
                }}
              />
              <SideModalFilter />
            </WppGrid>
            <WppGrid item all={24}>
              <Table
                table={tableData}
                onColumnOrderChange={(order: ORDER_BY, orderByColumn: string) => {
                  console.log(order, orderByColumn)
                }}
              />
            </WppGrid>
          </WppGrid>
        </WppGrid>
      </WppGrid>
    </>
  )
}

export default ChangeLog
