import { Text, View } from '@react-pdf/renderer'
import _ from 'lodash'
import React from 'react'
import { Trans } from 'react-i18next'

import styles from 'app/components/action/pdfViewer/category/styles'
import Form from 'app/components/action/pdfViewer/form'
import IApp from 'interfaces/app/IApp'
import ICategory from 'interfaces/category/ICategory'
import IForm from 'interfaces/form/IForm'

interface IPDFCategoryProps {
  category: ICategory
  app: IApp
  projectQuestionnaireId: string
}

/**
 * Category component
 * @param {object} props
 * @param {ICategory} props.category
 * @param {string} props.projectQuestionnaireId
 */
const PDFCategory: React.FC<IPDFCategoryProps> = ({
  category,
  app,
  projectQuestionnaireId,
}: IPDFCategoryProps): React.ReactElement => {
  return (
    <View>
      <View style={styles.categoryContainer}>
        <Text style={styles.categoryHeader}>{category.name}</Text>
      </View>
      {_.isEqual(_.first(app.categories)?.id, category.id) && (
        <View>
          <Text style={styles.fieldHeader}>
            <Trans>form.briefIdNumber</Trans>
          </Text>
          <Text style={styles.text}>{projectQuestionnaireId}</Text>
        </View>
      )}
      <View style={styles.formsContainer}>
        {category.forms.map((form: IForm) => (
          <Form key={form.id} form={form} />
        ))}
      </View>
    </View>
  )
}

export default PDFCategory
