import { Text, View } from '@react-pdf/renderer'
import React from 'react'

import styles from 'app/components/action/pdfViewer/category/styles'
import Form from 'app/components/action/pdfViewer/form'
import ICategory from 'interfaces/category/ICategory'
import IForm from 'interfaces/form/IForm'

interface IPDFCategoryProps {
  /** ICategory interface */
  category: ICategory
}

/**
 * Category component
 * @param {object} props
 * @param {ICategory} props.category
 */
const PDFCategory: React.FC<IPDFCategoryProps> = ({ category }: IPDFCategoryProps): React.ReactElement => {
  return (
    <View>
      <View style={styles.categoryContainer}>
        <Text style={styles.categoryHeader}>{category.name}</Text>
      </View>
      <View style={styles.formsContainer}>
        {category.forms.map((form: IForm) => (
          <Form key={form.id} form={form} />
        ))}
      </View>
    </View>
  )
}

export default PDFCategory
