import * as _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'

import styles from 'app/components/form/Form.module.scss'
import FormBuilder from 'app/components/form/formBuilder/FormBuilder'
import IdeaBriefSummaryTemplate from 'app/templates/IdeaBrief'
import { WppGrid, WppProgressIndicator, WppTypography } from 'buildingBlocks'
import ICategory from 'interfaces/category/ICategory'
import IFieldComment from 'interfaces/field/fieldComment/IFieldComment'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import { RootState } from 'store'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'

interface IFormProps {
  /** handle input change event. It has IFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
  /** Handle field comment */
  handleComment: (field: IField, category: ICategory, value: string) => void
  /** Form Field comments */
  setFormFieldComments: (field: IField, fieldComment: IFieldComment) => void
}

/**
 * Display form
 * @param {object} props
 * @param {(event: IFieldChangeEvent) => void} props.handleChange
 * @param {(field: IField, category: ICategory, value: string) => void} props.handleComment
 * @param {(field: IField, fieldComment: IFieldComment) => void} props.setFormFieldComments
 */
const Form: React.FC<IFormProps> = ({
  handleChange,
  handleComment,
  setFormFieldComments,
}: IFormProps): React.ReactElement => {
  const { app, activeStep } = useSelector<RootState, IQuestionnaireState>(
    (state: RootState) => state.questionnaireState,
  )

  if (!app) return <></>

  const { completionRate } = app

  const currentCategory = app.categories[activeStep - 1]

  return (
    <div className={styles.container}>
      <WppGrid fullWidth container>
        <WppGrid item all={24}>
          <WppTypography className={styles.completionRate} tag="p" type="s-body">
            You completed <span className={styles.completionNumber}>{completionRate.completed}</span> of{' '}
            <span className={styles.completionNumber}>{completionRate.total}</span> fields.
          </WppTypography>
          <WppProgressIndicator variant="bar" value={completionRate.percentage} />
        </WppGrid>
        <WppGrid item xl={12} xxl={12} sm={24} md={16} all={22} className={styles.categoryContainer}>
          <WppTypography tag="h1" type="xl-heading">
            {currentCategory.name}
          </WppTypography>
          {!_.isEmpty(currentCategory.description) && (
            <WppTypography type="s-body" tag="p">
              <span
                dangerouslySetInnerHTML={{
                  __html: currentCategory.description,
                }}
              />
            </WppTypography>
          )}
        </WppGrid>
        <WppGrid item all={24} className={styles.formContainer}>
          <FormBuilder
            forms={currentCategory.forms}
            handleComment={(field: IField, value: string) => handleComment(field, currentCategory, value)}
            handleChange={(event: IFieldChangeEvent) =>
              handleChange({
                ...event,
                categoryId: currentCategory.id,
              })
            }
            setFormFieldComments={setFormFieldComments}
          />
        </WppGrid>
        <WppGrid item all={24}>
          {app && _.isEqual(app.appName, 'Idea Brief') && _.isEqual(currentCategory.name, 'The Summary') && (
            <IdeaBriefSummaryTemplate app={app} />
          )}
          {app && _.isEqual(app.appName, 'Idea Brief') && _.isEqual(currentCategory.name, 'Template') && (
            <IdeaBriefSummaryTemplate app={app} isEmpty />
          )}
        </WppGrid>
      </WppGrid>
    </div>
  )
}

export default Form
