import _ from 'lodash'
import React, { Fragment } from 'react'

import AddFormField from 'app/components/form/formBuilder/formField/actions/Add'
import FormFieldBuilder from 'app/components/form/formBuilder/formFieldBuilder'
import IFieldComment from 'interfaces/field/fieldComment/IFieldComment'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import IRepeatableField from 'interfaces/field/repeatable/IRepeatableField'

interface FieldProps {
  /** field element */
  field: IField
  /** Handle input change event. It has InputFieldChangeEvent object which have IField, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
  /** Handle field comment */
  handleComment: (field: IField, value: string) => void
  /** Form Field comments */
  setFormFieldComments: (field: IField, fieldComment: IFieldComment) => void
  /** Is children*/
  isChildField: boolean
}

/**
 * Build field
 * @param {object} props
 * @param {IForm} props.form
 * @param {boolean} props.isChildField
 * @param {(event: IFieldChangeEvent) => void} props.handleChange
 * @param {(field: IField, value: string) => void} props.handleComment
 * @param {(field: IField, fieldComment: IFieldComment) => void} props.setFormFieldComments
 */
const Field: React.FC<FieldProps> = (props: FieldProps): React.ReactElement => {
  const { field } = props

  if (field.isHidden) {
    return <Fragment key={field.id} />
  }

  if (_.lte(field.maxRepeatableAmount, 0)) {
    return (
      <div key={field.id}>
        <FormFieldBuilder {...props} field={field} isFirstField />
      </div>
    )
  }

  return (
    <>
      {_.orderBy(field.repeatableFields, ['order'], 'asc').map(
        ({ id, value, isValid, touched, errorMessage, isCompleted }: IRepeatableField, fieldIndex: number) => {
          let fieldTitle = field.fieldConfig.text
          if (!_.isEmpty(fieldTitle) && _.gt(field.maxRepeatableAmount, 1)) {
            fieldTitle = `${field.fieldConfig.text} ${fieldIndex + 1}`
          }
          return (
            <div key={id}>
              <FormFieldBuilder
                {...props}
                handleChange={(event: IFieldChangeEvent) =>
                  props.handleChange({
                    ...event,
                    repeatableFieldId: id,
                  })
                }
                field={{
                  ...field,
                  value,
                  fieldConfig: {
                    ...field.fieldConfig,
                    text: fieldTitle,
                  },
                  errorMessage,
                  messageType: !isValid && touched ? 'error' : undefined,
                  isCompleted,
                }}
                isFirstField={_.isEqual(fieldIndex, 0)}
                repeatableFieldId={id}
              />
              {!field.disabled &&
                _.gt(field.maxRepeatableAmount, 0) &&
                _.isEqual(fieldIndex + 1, field.repeatableFields.length) && (
                  <AddFormField field={field} handleChange={props.handleChange} />
                )}
            </div>
          )
        },
      )}
    </>
  )
}

export default Field
