import { AxiosError, AxiosResponse } from 'axios'
import HTTP_STATUS_CODES from 'http-status-codes'
import _ from 'lodash'

import {
  deleteReviewAPIPath,
  getQuestionnaireReviewAPIPath,
  getReviewerDataAPIPath,
  getReviewerInfoAPIPath,
  sendToReviewAPIPath,
  updateReviewAPIPath,
} from 'config/apiPaths'
import {
  QUESTIONNAIRE_STATUS,
  QUESTIONNAIRE_STATUS_VALUES,
  REVIEW_ACTION_TYPES,
  REVIEW_RESPONSE_TYPES,
  REVIEW_TYPES,
  TAG_VARIANTS,
  TOAST_MESSAGE_TYPES,
} from 'config/enums'
import IPaginator from 'interfaces/common/IPaginator'
import IProjectMember from 'interfaces/project/IProjectMember'
import IApproval from 'interfaces/review/IApproval'
import IReviewerInfo from 'interfaces/review/IReviewerInfo'
import IParamCancelReview from 'interfaces/review/params/IParamCancelReview'
import IParamQuestionnaireReviewer from 'interfaces/review/params/IParamQuestionnaireReviewer'
import IParamReviewerAction from 'interfaces/review/params/IParamReviewerAction'
import IParamSendToReview from 'interfaces/review/params/IParamSendToReview'
import IRTQuestionnaireReviewer from 'interfaces/review/returnTypes/IRTQuestionnaireReviewer'
import IRTSendToReviewNotification from 'interfaces/review/returnTypes/IRTSendToReviewNotification'
import IRTUpdateReviewNotification from 'interfaces/review/returnTypes/IRTUpdateReviewNotification'
import AxiosService from 'lib/AxiosService'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import { setErrorAlert, showAlert, showErrorAlert } from 'store/reducers/alertSlice'
import { setApproval } from 'store/reducers/projectBriefSlice'
import { setApp, setLoading, setState } from 'store/reducers/questionnaireSlice'
import {
  setApproveModal,
  setRejectModal,
  setReviewerInfo,
  setSelectedReviewers,
  setSendToReviewModal,
} from 'store/reducers/reviewSlice'
import FormHelper from 'utils/form/FormHelper'
import FormValidation from 'utils/form/FormValidation'
import PermissionHelper from 'utils/permission/PermissionHelper'
import QuestionnaireHelper from 'utils/questionnaire/QuestionnaireHelper'
import SharedHelper from 'utils/SharedHelper'

export default class ReviewHelper {
  public static getApprovalData(approval: IApproval | null | undefined, isReviewer: boolean = false): IApproval | null {
    if (!approval) return null
    const { completedAt, issuerFirstname, issuerLastname, requestedAt } = approval

    let statusVariant: TAG_VARIANTS = TAG_VARIANTS.NEUTRAL
    const statusValue: string = approval.status
    statusVariant = _.isEqual(statusValue, QUESTIONNAIRE_STATUS.SUCCEEDED) ? TAG_VARIANTS.POSITIVE : statusVariant
    statusVariant = _.isEqual(statusValue, QUESTIONNAIRE_STATUS.FAILED) ? TAG_VARIANTS.NEGATIVE : statusVariant

    let statusStr = 'Draft'
    statusStr = _.isEqual(statusValue, QUESTIONNAIRE_STATUS.SUCCEEDED)
      ? QUESTIONNAIRE_STATUS_VALUES.APPROVED
      : statusStr
    statusStr = _.isEqual(statusValue, QUESTIONNAIRE_STATUS.PENDING)
      ? QUESTIONNAIRE_STATUS_VALUES.SENT_FOR_APPROVAL
      : statusStr

    statusStr = _.isEqual(statusValue, QUESTIONNAIRE_STATUS.FAILED) ? QUESTIONNAIRE_STATUS_VALUES.REJECTED : statusStr

    const approvalData: IApproval = {
      ...approval,
      issuerFullname: `${issuerFirstname} ${issuerLastname}`,
      completedAt: completedAt ? SharedHelper.getFormattedDateInUTC(completedAt) : '',
      requestedAt: SharedHelper.getFormattedDateInUTC(requestedAt),
      statusVariant,
      statusStr,
      isReviewer,
    }
    return approvalData
  }

  /**
   * If logged-in user is a reviewer
   * @param {string} accessToken
   * @param {string} tenantId
   * @param {string} projectQuestionnaireId
   * @returns {Promise<boolean>}
   */
  public static async getCurrentUserReviewInfo(
    accessToken: string,
    tenantId: string,
    projectQuestionnaireId: string,
  ): Promise<IReviewerInfo | null> {
    try {
      const axiosService = new AxiosService(accessToken)
      const approval: AxiosResponse<IReviewerInfo | null> = await axiosService.get(
        getReviewerInfoAPIPath(projectQuestionnaireId),
        tenantId,
      )

      return approval.data
    } catch {
      return null
    }
  }

  /**
   * Send to review
   * @param {string} accessToken
   * @param {string[]} reviewersEmail
   * @param {REVIEW_TYPES} reviewType
   * @param {string} projectQuestionnaireId
   * @param {string} tenantId
   * @param {string} appInstanceId
   * @param {boolean} isExistingReview
   * @returns {Promise<IReview | null>}
   */
  public async sendToReview(
    accessToken: string,
    reviewersEmail: string[],
    reviewType: REVIEW_TYPES,
    projectQuestionnaireId: string,
    tenantId: string,
    appInstanceId: string,
    isExistingReview: boolean,
  ): Promise<IRTSendToReviewNotification | null> {
    const axiosService = new AxiosService(accessToken)

    const review: AxiosResponse<IRTSendToReviewNotification | null> = await axiosService[
      isExistingReview ? 'patch' : 'post'
    ](
      sendToReviewAPIPath(projectQuestionnaireId),
      { reviewers: reviewersEmail, successConstraint: reviewType },
      tenantId,
      {
        headers: {
          'X-App-Instance-Id': appInstanceId,
        },
      },
    )

    if (!review.data) {
      return null
    }

    return review.data
  }

  /**
   * Update review status
   * @param {string} accessToken
   * @param {string} projectQuestionnaireId
   * @param {REVIEW_RESPONSE_TYPES} responseType
   * @param {string} responseReason
   * @param {string} tenantId
   * @param {string} appInstanceId
   * @returns {Promise<IReview | null>}
   */
  public async updateReviewStatus(
    accessToken: string,
    projectQuestionnaireId: string,
    responseType: REVIEW_RESPONSE_TYPES,
    responseReason: string,
    tenantId: string,
    appInstanceId: string,
  ): Promise<IRTUpdateReviewNotification | null> {
    const axiosService = new AxiosService(accessToken)
    const review: AxiosResponse<IRTUpdateReviewNotification> = await axiosService.post(
      updateReviewAPIPath(projectQuestionnaireId),
      {
        responseType,
        responseReason,
      },
      tenantId,
      {
        headers: {
          'X-App-Instance-Id': appInstanceId,
        },
      },
    )

    if (!review.data) {
      return null
    }

    return review.data
  }

  /**
   * Delete Review
   * @param {string} accessToken
   * @param {string} projectQuestionnaireId
   * @param {string} tenantId
   * @param {string} appInstanceId
   * @returns {Promise<boolean>}
   */
  public async deleteReview(
    accessToken: string,
    projectQuestionnaireId: string,
    tenantId: string,
    appInstanceId: string,
  ): Promise<boolean> {
    const axiosService = new AxiosService(accessToken)
    const response: AxiosResponse<{ notificationSubmitted: boolean }> = await axiosService.delete(
      deleteReviewAPIPath(projectQuestionnaireId),
      tenantId,
      {
        headers: {
          'X-App-Instance-Id': appInstanceId,
        },
      },
    )
    return response.data.notificationSubmitted
  }

  /**
   * Verify review status
   * @param {string} accessToken
   * @param {string} projectQuestionnaireId
   * @param {string} tenantId
   * @param {REVIEW_ACTION_TYPES} status
   * @param {Function} dispatch
   * @param {TFunction} t
   * @returns {Promise<boolean>}
   */
  public static async verifyReviewStatus(
    accessToken: string,
    projectQuestionnaireId: string,
    tenantId: string,
    reviewActionType: REVIEW_ACTION_TYPES,
    message: string,
    dispatch: Function,
  ): Promise<boolean> {
    try {
      const response: IApproval | null = await this.getApprovalStatus(accessToken, projectQuestionnaireId, tenantId)
      let isActionAllowed = false
      switch (reviewActionType) {
        case REVIEW_ACTION_TYPES.APPROVE_REJECT_ACTION:
        case REVIEW_ACTION_TYPES.CANCEL_REVIEW: {
          isActionAllowed = !_.isNull(response) && _.isEqual(response.status, QUESTIONNAIRE_STATUS.PENDING)
          break
        }
        case REVIEW_ACTION_TYPES.SEND_TO_REVIEW: {
          isActionAllowed = _.isNull(response) || _.isEqual(response.status, QUESTIONNAIRE_STATUS.FAILED)
          break
        }
      }

      if (!isActionAllowed) {
        dispatch(setErrorAlert(message))
      }
      return isActionAllowed
    } catch (error) {
      const err = error as AxiosError
      if (_.isEqual(err.response?.status, HTTP_STATUS_CODES.NOT_FOUND)) {
        if (!_.isEqual(reviewActionType, REVIEW_ACTION_TYPES.SEND_TO_REVIEW)) {
          dispatch(setErrorAlert(message))
          return false
        }
        return true
      }
      dispatch(showErrorAlert())
      return false
    }
  }

  /**
   * Handle reviewer action
   * @param {IHandleReviewerAction}
   */
  public static readonly handleReviewerAction = async ({
    accessToken,
    tenantId,
    appInstanceId,
    projectQuestionnaireId,
    questionnaireState,
    responseType,
    responseReason,
    t,
    dispatch,
    appContext,
  }: IParamReviewerAction): Promise<void> => {
    const { app } = questionnaireState
    try {
      if (!app) return
      dispatch(setLoading(true))

      const reviewHelper = new ReviewHelper()
      const approvalData: IRTUpdateReviewNotification | null = await reviewHelper.updateReviewStatus(
        accessToken,
        projectQuestionnaireId,
        responseType,
        responseReason,
        tenantId,
        appInstanceId,
      )

      if (approvalData) {
        dispatch(setApproval(this.getApprovalData(approvalData.data.approval, app.isProjectMember)))
        dispatch(setReviewerInfo(approvalData.data))

        if (_.isEqual(approvalData.data.approval?.status, QUESTIONNAIRE_STATUS.FAILED)) {
          const formHelper = new FormHelper()
          const updatedApp = formHelper.updateEditableAppStatus(
            app,
            PermissionHelper.hasAppEditor(appContext.permissions),
          )
          dispatch(setApp(updatedApp))
        }

        let statusMessage = t(
          _.isEqual(approvalData.data.responseType, REVIEW_RESPONSE_TYPES.APPROVAL)
            ? 'review.notification.action.approved'
            : 'review.notification.action.rejected',
        )

        if (approvalData.notificationSubmitted) {
          dispatch(
            showAlert({
              message: statusMessage,
              type: TOAST_MESSAGE_TYPES.SUCCESS,
            }),
          )
        } else {
          dispatch(
            showAlert({
              message: t('review.notification.action.error', { statusMessage }),
              type: TOAST_MESSAGE_TYPES.WARNING,
            }),
          )
        }
      }
      dispatch(setLoading(false))

      if (_.isEmpty(responseReason)) {
        dispatch(setApproveModal(false))
        return
      }
      dispatch(setRejectModal(false))
    } catch (error) {
      dispatch(setLoading(false))
      const err = error as AxiosError
      if (_.isEqual(err.response?.status, HTTP_STATUS_CODES.NOT_FOUND)) {
        dispatch(setErrorAlert(t('review.error.canceled')))
        return
      } else if (_.isEqual(err.response?.status, HTTP_STATUS_CODES.CONFLICT)) {
        dispatch(setErrorAlert(t('review.error.conflict')))
        return
      }
      dispatch(showErrorAlert())
    }
  }

  /**
   * Handle cancel review
   * @param {IParamCancelReview}
   */
  public static readonly handleCancelReview = async ({
    accessToken,
    tenantId,
    appInstanceId,
    projectQuestionnaireId,
    questionnaireState,
    appContext,
    approval,
    t,
    dispatch,
  }: IParamCancelReview) => {
    const { app } = questionnaireState

    if (!app || !approval) return
    try {
      dispatch(setLoading(true))

      const isValidReview = await this.verifyReviewStatus(
        accessToken,
        projectQuestionnaireId,
        tenantId,
        REVIEW_ACTION_TYPES.CANCEL_REVIEW,
        t('review.error.alreadyUpdated'),
        dispatch,
      )

      if (!isValidReview) {
        dispatch(setLoading(false))
        return
      }

      const reviewHelper = new ReviewHelper()
      const notificationSubmitted: boolean = await reviewHelper.deleteReview(
        accessToken,
        projectQuestionnaireId,
        tenantId,
        appInstanceId,
      )
      const formHelper = new FormHelper()

      if (notificationSubmitted) {
        dispatch(
          showAlert({
            message: t('review.notification.cancelReview.success'),
            type: TOAST_MESSAGE_TYPES.SUCCESS,
          }),
        )
      } else {
        dispatch(
          showAlert({
            message: t('review.notification.cancelReview.error'),
            type: TOAST_MESSAGE_TYPES.WARNING,
          }),
        )
      }

      const updatedApp = formHelper.updateEditableAppStatus(app, PermissionHelper.hasAppEditor(appContext.permissions))
      dispatch(
        setState({
          ...questionnaireState,
          app: updatedApp,
          loading: false,
        }),
      )
      dispatch(setApproval(null))
    } catch {
      dispatch(setLoading(false))
    }
  }

  /**
   * Handle send to review
   * @param {IParamSendToReview}
   */
  public static readonly handleSendToReview = async ({
    accessToken,
    appContext,
    projectQuestionnaireId,
    questionnaireState,
    reviewers,
    reviewType,
    approval,
    category,
    t,
    dispatch,
  }: IParamSendToReview): Promise<void> => {
    const { app } = questionnaireState
    const { tenantId, appInstanceId } = appContext
    if (!app) return

    const formValidation = new FormValidation()
    const updatedApp = formValidation.validateAppByCategoryId(app)

    if (!updatedApp.isValid) {
      dispatch(
        showAlert({
          message: t('app.error.validation'),
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      )
      const updatedState: IQuestionnaireState = { ...questionnaireState, app: updatedApp }
      dispatch(setState(updatedState))
      return
    }

    try {
      dispatch(setLoading(true))

      const isValidReview = await this.verifyReviewStatus(
        accessToken,
        projectQuestionnaireId,
        tenantId,
        REVIEW_ACTION_TYPES.SEND_TO_REVIEW,
        t('review.error.alreadyCreated'),
        dispatch,
      )

      if (!isValidReview) {
        dispatch(setLoading(false))
        return
      }

      await QuestionnaireHelper.onSaveProgress(
        accessToken,
        appContext,
        questionnaireState,
        projectQuestionnaireId,
        category,
        dispatch,
      )
      const reviewHelper = new ReviewHelper()
      const reviewersEmail: string[] = reviewers.map((reviewer: IProjectMember) => reviewer.email)
      const approvalData: IRTSendToReviewNotification | null = await reviewHelper.sendToReview(
        accessToken,
        reviewersEmail,
        reviewType,
        projectQuestionnaireId,
        tenantId,
        appInstanceId,
        !_.isNull(approval),
      )
      if (approvalData) {
        if (approvalData.notificationSubmitted) {
          dispatch(
            showAlert({
              message: t('review.notification.sendToReview.success'),
              type: TOAST_MESSAGE_TYPES.SUCCESS,
            }),
          )
        } else {
          dispatch(
            showAlert({
              message: t('review.notification.sendToReview.error'),
              type: TOAST_MESSAGE_TYPES.WARNING,
            }),
          )
        }
        const formHelper = new FormHelper()
        const updatedApp = formHelper.updateEditableAppStatus(app, false)

        dispatch(
          setState({
            ...questionnaireState,
            app: updatedApp,
          }),
        )
        dispatch(setApproval(this.getApprovalData(approvalData.data)))
      }
      dispatch(setLoading(false))
      dispatch(setSendToReviewModal(false))
    } catch (error) {
      dispatch(setLoading(false))
      const err = error as AxiosError
      if (_.isEqual(err.response?.status, HTTP_STATUS_CODES.CONFLICT)) {
        dispatch(setErrorAlert(t('review.error.conflict')))
        return
      }
      dispatch(showErrorAlert())
    }
  }

  /**
   * Get approval status
   * @param {string} accessToken
   * @param {string} projectQuestionnaireId
   * @param {string} tenantId
   * @returns {Promise<IApproval>}
   */
  public static readonly getApprovalStatus = async (
    accessToken: string,
    projectQuestionnaireId: string,
    tenantId: string,
  ): Promise<IApproval | null> => {
    const axiosService = new AxiosService(accessToken)
    const response: AxiosResponse<IApproval> = await axiosService.get(
      getQuestionnaireReviewAPIPath(projectQuestionnaireId),
      tenantId,
    )

    const isReviewer = await this.getCurrentUserReviewInfo(accessToken, tenantId, projectQuestionnaireId)
    return this.getApprovalData(response.data, !!isReviewer)
  }

  /**
   * Get questionnaire reviewers
   * @param {IParamQuestionnaireReviewer}
   */
  public static readonly getQuestionnaireReviewers = async ({
    accessToken,
    dispatch,
    projectQuestionnaireId,
    reviewUpdatedAt,
    t,
    tenantId,
  }: IParamQuestionnaireReviewer): Promise<IRTQuestionnaireReviewer> => {
    try {
      const axiosService = new AxiosService(accessToken)
      const headers = reviewUpdatedAt ? { 'X-If-Approval-Not-Modified-Since': reviewUpdatedAt } : {}

      const response: AxiosResponse<{ data: IReviewerInfo[]; paginator: IPaginator }> = await axiosService.get(
        getReviewerDataAPIPath(projectQuestionnaireId),
        tenantId,
        {
          headers,
        },
      )

      const selectedReviewers: IReviewerInfo[] = response.data.data
      dispatch(setSelectedReviewers(selectedReviewers))
      return { status: HTTP_STATUS_CODES.OK, reviewers: selectedReviewers }
    } catch (error) {
      const err = error as AxiosError
      if (_.isEqual(err.response?.status, HTTP_STATUS_CODES.PRECONDITION_FAILED)) {
        const approval: IApproval | null = await this.getApprovalStatus(accessToken, projectQuestionnaireId, tenantId)
        dispatch(setErrorAlert(t('review.error.alreadyUpdated')))
        return this.getQuestionnaireReviewers({
          accessToken,
          dispatch,
          projectQuestionnaireId,
          reviewUpdatedAt: approval ? approval.updatedAt : null,
          t,
          tenantId,
        })
      } else if (_.isEqual(err.response?.status, HTTP_STATUS_CODES.NOT_FOUND)) {
        return { status: HTTP_STATUS_CODES.NOT_FOUND, reviewers: [] }
      }
      dispatch(showErrorAlert())
      return { reviewers: [] }
    }
  }
}
