import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import styles from 'app/components/form/formField/formFieldComment/formFieldCommentDetail/FormFieldCommentDetail.module.scss'
import { WppAvatar, WppProgressIndicator, WppTypography } from 'buildingBlocks'
import IComment from 'interfaces/field/fieldComment/IComment'
import IField from 'interfaces/field/IField'

interface IFormFieldCommentDetailProps {
  /** Field object */
  field: IField
  /** Load comments */
  loadMoreComment: Function
}

/**
 * Display field comments
 * @param {object} props
 * @param {IField} props.field
 * @param {Function} props.loadMoreComment
 */
const FormFieldCommentDetail: React.FC<IFormFieldCommentDetailProps> = ({
  field,
  loadMoreComment,
}: IFormFieldCommentDetailProps): React.ReactElement => {
  const { comments } = field.fieldComment

  if (_.isEmpty(comments)) {
    return <></>
  }

  return (
    <div id="scrollableDiv" className={styles.rootContainer}>
      <InfiniteScroll
        dataLength={comments.length}
        next={() => loadMoreComment()}
        className={styles.infiniteScrollContainer}
        inverse={true}
        hasMore={field.fieldComment.hasMore}
        loader={<WppProgressIndicator />}
        scrollableTarget="scrollableDiv"
      >
        {_.orderBy(comments, ['createdAt'], ['desc']).map(
          ({ id, issuerFullname, issuerEmail, issuerAvatarUrl, createdAt, value }: IComment) => (
            <div key={id} className={styles.commentContainer}>
              <div className={styles.userInfo}>
                <WppAvatar name={`${issuerFullname} - ${issuerEmail}`} withTooltip src={issuerAvatarUrl} />
                <WppTypography tag="p" type="s-strong">
                  {issuerFullname}
                </WppTypography>
                <WppTypography tag="p" type="s-body">
                  {moment.utc(createdAt).fromNow()}
                </WppTypography>
              </div>
              <WppTypography tag="p" type="s-body">
                {value}
              </WppTypography>
            </div>
          ),
        )}
      </InfiniteScroll>
    </div>
  )
}

export default FormFieldCommentDetail
