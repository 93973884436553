import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DEFAULT_PAGINATOR_DATA } from 'config/constants'
import { FIELD_TYPES, ORDER_BY } from 'config/enums'
import IApp from 'interfaces/app/IApp'
import ICategory from 'interfaces/category/ICategory'
import ISelectedFilter from 'interfaces/changeLog/ISelectedFilter'
import IField from 'interfaces/field/IField'
import ITable from 'interfaces/table/ITable'
import IChangeLogState from 'store/interfaces/IChangeLogState'
import FilterHelper from 'utils/changeLog/FilterHelper'

const defaultChangeLogState: IChangeLogState = {
  filterSelectedData: {
    dateRange: FilterHelper.getFilterDefaultDateRange(),
    sideModal: {
      selectedCategories: [],
      selectedFormFields: [],
      selectedUserField: {
        id: '600',
        formFieldId: '600',
        isValid: false,
        isCompleted: false,
        touched: false,
        type: FIELD_TYPES.USER,
        maxRepeatableAmount: 0,
        repeatableFields: [],
        isRequired: false,
        disabled: false,
        fieldComment: {
          comments: [],
          page: 1,
          hasMore: true,
          startDate: null,
          uniqueUsers: [],
        },
        value: [],
        errorMessage: '',
        displayOrder: 10,
        children: [],
        visibleOn: null,
        visibleOnValue: [],
        fieldConfig: {
          text: '',
          categoryId: '',
          projectQuestionnaireId: '',
          tenantId: '',
          tooltip: '',
          placeholder: '',
          help: '',
          multi: true,
          options: [],
        },
      },
    },
  },
  filterData: {
    categories: [],
    fields: [],
  },
  tableData: {
    colDefs: [],
    rowData: [],
    paginator: DEFAULT_PAGINATOR_DATA,
    orderBy: ORDER_BY.DESC,
    loading: true,
    orderByColumn: 'date',
  },
  openFilterModal: false,
}

export const changeLogSlice = createSlice({
  name: 'changeLog',
  initialState: defaultChangeLogState,
  reducers: {
    setDateRangeFilter: (state: IChangeLogState, action: PayloadAction<string[]>): IChangeLogState => {
      return {
        ...state,
        filterSelectedData: {
          ...state.filterSelectedData,
          dateRange: action.payload,
        },
      }
    },
    setOpenFilterModal: (state: IChangeLogState, action: PayloadAction<boolean>): IChangeLogState => {
      return {
        ...state,
        openFilterModal: action.payload,
      }
    },
    setSelectedFilterData: (state: IChangeLogState, action: PayloadAction<ISelectedFilter>): IChangeLogState => {
      return {
        ...state,
        filterSelectedData: {
          ...state.filterSelectedData,
          sideModal: action.payload,
        },
        openFilterModal: false,
      }
    },
    setFilterFormFields: (state: IChangeLogState, action: PayloadAction<Array<ICategory>>): IChangeLogState => {
      const fields: Array<IField> = FilterHelper.getFieldsByCategories(action.payload)
      return {
        ...state,
        filterData: {
          ...state.filterData,
          fields,
        },
      }
    },
    setTableData: (state: IChangeLogState, action: PayloadAction<ITable>): IChangeLogState => {
      return {
        ...state,
        tableData: action.payload,
      }
    },
    setFilterData: (state: IChangeLogState, action: PayloadAction<IApp>): IChangeLogState => {
      const categories: Array<ICategory> = FilterHelper.getCategories(action.payload)
      const fields: Array<IField> = FilterHelper.getFieldsByCategories(categories)
      return {
        ...state,
        filterData: {
          ...state.filterData,
          categories,
          fields,
        },
        filterSelectedData: {
          ...state.filterSelectedData,
          sideModal: {
            ...state.filterSelectedData.sideModal,
            selectedCategories: categories,
            selectedFormFields: fields,
          },
        },
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setDateRangeFilter,
  setOpenFilterModal,
  setTableData,
  setFilterData,
  setFilterFormFields,
  setSelectedFilterData,
} = changeLogSlice.actions

export default changeLogSlice.reducer
