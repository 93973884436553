import { AxiosResponse } from 'axios'
import _ from 'lodash'
import moment from 'moment'

import { getChangeLogDataAPIPath } from 'config/apiPaths'
import { DATE_TIME_FORMAT } from 'config/constants'
import { ORDER_BY } from 'config/enums'
import IApp from 'interfaces/app/IApp'
import ICategory from 'interfaces/category/ICategory'
import IChangeLogData from 'interfaces/changeLog/IChangeLogData'
import IResponseChgLog from 'interfaces/changeLog/response/IResponseChgLog'
import IResponseChgLogField from 'interfaces/changeLog/response/IResponseChgLogField'
import IField from 'interfaces/field/IField'
import AxiosService from 'lib/AxiosService'

export default class ChangeLogHelper {
  /**
   * Get change log data
   * @param {string} accessToken
   * @param {string} projectQuestionnaireId
   * @param {IApp} app
   * @param {string} tenantId
   * @param {number} page
   * @param {ORDER_BY} sortOrder
   * @returns {Array<ICategory>}
   */
  public static async getChangeLogData(
    accessToken: string,
    projectQuestionnaireId: string,
    app: IApp,
    tenantId: string,
    page: number,
    sortOrder: ORDER_BY,
  ): Promise<Array<IChangeLogData>> {
    const axiosService = new AxiosService(accessToken)
    const response: AxiosResponse<IResponseChgLog> = await axiosService.get(
      getChangeLogDataAPIPath(projectQuestionnaireId, page, sortOrder),
      tenantId,
    )
    return this.buildTableData(app, response.data.data)
  }

  /**
   * Build table data
   * @param {IApp} app
   * @param {Array<IResponseChgLogField>} fieldData
   * @returns {Array<IChangeLogData>}
   */
  private static buildTableData(app: IApp, fieldData: Array<IResponseChgLogField>): Array<IChangeLogData> {
    return fieldData.map((fieldData: IResponseChgLogField) => {
      return {
        category: this.getCategoryNameById(app, fieldData.categoryId),
        date: fieldData.createdAt ? moment.utc(fieldData.createdAt).format(DATE_TIME_FORMAT) : '',
        fieldName: this.getFieldNameByFormFieldId(app, fieldData.categoryId, fieldData.formFieldId),
        newValue: fieldData.change ? fieldData.change.displayValue : '',
        oldValue: fieldData.displayValue,
        userEmail: fieldData.createdBy,
      }
    })
  }

  /**
   * Get category name by category id
   * @param {IApp} app
   * @param {ICategory} categoryId
   * @returns {string}
   */
  private static getCategoryNameById(app: IApp, categoryId: string): string {
    for (let category of app.categories) {
      if (_.isEqual(category.id, categoryId)) {
        return category.name
      }
    }
    return ''
  }

  /**
   * Get field name by category id
   * @param {IApp} app
   * @param {string} categoryId
   * @param {string} formFieldId
   * @returns {string}
   */
  private static getFieldNameByFormFieldId(app: IApp, categoryId: string, formFieldId: string): string {
    const category: ICategory | undefined = _.find(app.categories, { id: categoryId })
    if (!category) return ''

    let fieldName: string = ''

    for (let form of category.forms) {
      for (let field of form.fields) {
        fieldName = this.getFieldNameById(field, formFieldId)
        if (!_.isEmpty(fieldName)) return fieldName
      }
    }
    return fieldName
  }

  /**
   * Get field name by category id
   * @param {IField} field
   * @param {string} formFieldId
   * @returns {string}
   */
  private static getFieldNameById(field: IField, formFieldId: string): string {
    if (_.isEqual(field.formFieldId, formFieldId)) {
      return field.fieldConfig.text
    }

    for (let childField of field.children) {
      return this.getFieldNameById(childField, formFieldId)
    }
    return ''
  }
}
