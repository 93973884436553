import _ from 'lodash'
import React from 'react'

import FormFieldComment from 'app/components/form//formField/formFieldComment'
import RemoveFormField from 'app/components/form/formBuilder/formField/actions/Remove'
import styles from 'app/components/form/formBuilder/formFieldBuilder/FormFieldBuilder.module.scss'
import FormFieldAutoComplete from 'app/components/form/formField/formFieldAutoComplete'
import FormFieldCheckbox from 'app/components/form/formField/formFieldCheckbox'
import FormFieldCounter from 'app/components/form/formField/formFieldCounter'
import FormFieldCurrency from 'app/components/form/formField/formFieldCurrency'
import FormFieldDatePicker from 'app/components/form/formField/formFieldDatePicker'
import FormFieldFileUpload from 'app/components/form/formField/formFieldFileUpload'
import FormFieldLabel from 'app/components/form/formField/formFieldLabel'
import FormFieldNumber from 'app/components/form/formField/formFieldNumber'
import FormFieldRadio from 'app/components/form/formField/formFieldRadio'
import FormFieldSelect from 'app/components/form/formField/formFieldSelect'
import FormFieldTextarea from 'app/components/form/formField/formFieldTextarea'
import FormFieldTextInput from 'app/components/form/formField/formFieldTextInput'
import FormFieldUser from 'app/components/form/formField/FormFieldUser'
import { WppGrid } from 'buildingBlocks'
import { FIELD_TYPES } from 'config/enums'
import IGridColumn from 'interfaces/common/IGridColumn'
import IFieldComment from 'interfaces/field/fieldComment/IFieldComment'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'

interface IFormFieldBuilderProps {
  /** IField object */
  field: IField
  /** Handle input change event. It has InputFieldChangeEvent object which have IField, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
  /** Handle field comment */
  handleComment: (field: IField, value: string) => void
  /** If field is a child*/
  isChildField?: boolean
  /** Form Field comments */
  setFormFieldComments: (field: IField, fieldComment: IFieldComment) => void
  /** If the field is first element */
  isFirstField: boolean
  /** Repeatable Field Id */
  repeatableFieldId?: string
}

/**
 * Build form field
 * @param {object} props Component props
 * @param {IField} props.field Field object
 * @param {boolean} props.isChildField
 * @param {(event: IFieldChangeEvent) => void} props.handleChange Handle input field change event
 * @param {(field: IField, value: string) => void} props.handleComment Handle field comment
 * @param {(field: IField, fieldComment: IFieldComment) => void} props.setFormFieldComments
 * @param {boolean} props.isFirstField
 * @param {boolean} props.repeatableFieldId
 */
const FormFieldBuilder: React.FC<IFormFieldBuilderProps> = (props: IFormFieldBuilderProps): React.ReactElement => {
  let fieldElement = <></>

  const formField: IField = { ...props.field }
  if (formField.isHidden) {
    return fieldElement
  }

  const updatedProps = _.omit(props, ['setFormFieldComments', 'handleComment', 'isChildField'])

  const { fieldConfig } = formField

  switch (formField.type) {
    // Text Input Field
    case FIELD_TYPES.TEXT_INPUT:
      fieldElement = <FormFieldTextInput field={formField} handleChange={props.handleChange} inputType="text" />
      break
    // Number Field
    case FIELD_TYPES.NUMBER:
      fieldElement = <FormFieldNumber field={formField} handleChange={props.handleChange} />
      break
    // Radio Field
    case FIELD_TYPES.RADIO:
      fieldElement = (
        <FormFieldRadio
          {...updatedProps}
          setFormFieldComments={props.setFormFieldComments}
          handleComment={props.handleComment}
          field={formField}
        />
      )
      break
    // Checkbox Field
    case FIELD_TYPES.CHECKBOX:
      fieldElement = (
        <FormFieldCheckbox
          {...updatedProps}
          setFormFieldComments={props.setFormFieldComments}
          handleComment={props.handleComment}
          field={formField}
        />
      )
      break
    // Counter Field
    case FIELD_TYPES.COUNTER:
      fieldElement = <FormFieldCounter {...updatedProps} field={formField} />
      break
    // Textarea Field
    case FIELD_TYPES.TEXT_AREA:
      fieldElement = <FormFieldTextarea {...updatedProps} field={formField} />
      break
    // Autocomplete Field
    case FIELD_TYPES.AUTOCOMPLETE:
      fieldElement = <FormFieldAutoComplete {...updatedProps} field={formField} />
      break
    // User Field
    case FIELD_TYPES.USER:
      fieldElement = <FormFieldUser {...updatedProps} field={formField} />
      break
    // File upload Field
    case FIELD_TYPES.FILE_UPLOAD:
      fieldElement = <FormFieldFileUpload {...updatedProps} field={formField} />
      break
    // Currency Field
    case FIELD_TYPES.CURRENCY:
      fieldElement = <FormFieldCurrency {...updatedProps} field={formField} />
      break
    // Select/Market Field
    case FIELD_TYPES.SELECT:
    case FIELD_TYPES.MARKET:
      fieldElement = <FormFieldSelect {...updatedProps} field={formField} />
      break
    // DatePicker Field
    case FIELD_TYPES.DATE_PICKER:
      fieldElement = <FormFieldDatePicker {...updatedProps} field={formField} />
      break
  }

  const gridColumns: IGridColumn = props.isChildField
    ? {
        all: 22,
      }
    : {
        xl: 12,
        xxl: 12,
        sm: 22,
        md: 16,
        all: 22,
      }

  return (
    <>
      <WppGrid container fullWidth>
        <WppGrid className={styles.labelContainer} item {...gridColumns}>
          <div>
            <FormFieldLabel
              id={formField.id}
              text={fieldConfig.text}
              helpModal={fieldConfig.helpModal}
              tooltip={fieldConfig.tooltip}
              disabled={props.field.disabled}
              description={fieldConfig.description}
              required={formField.isRequired}
              isCompletedField={formField.isCompleted}
            />
          </div>
          {!props.isFirstField && !formField.disabled && (
            <RemoveFormField
              field={formField}
              handleChange={props.handleChange}
              repeatableFieldId={props.repeatableFieldId}
            />
          )}
        </WppGrid>
      </WppGrid>
      <WppGrid container fullWidth>
        <WppGrid className={styles.itemContainer} item {...gridColumns}>
          {fieldElement}
        </WppGrid>
        <WppGrid item all={2}>
          {props.isFirstField && (
            <FormFieldComment
              field={formField}
              handleComment={props.handleComment}
              setFormFieldComments={props.setFormFieldComments}
            />
          )}
        </WppGrid>
      </WppGrid>
    </>
  )
}

export default FormFieldBuilder
